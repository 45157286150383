<template>
	<div class="content">
		<div v-if="profile" class="profile">
		
			<span v-if="profile.logoImage != ''">
				<img v-bind:id="createElementId('logo', profile.uniqueId)" width="150px" v-bind:src="profile.logoImage" /> 
			</span>

			<h2>{{ profile.name }}</h2>			
			
			<Address :address="profile.billingAddress"></Address>
			
			<p>
				<span class="fieldName">Company Phone:</span> <span class="fieldData">{{ profile.phone }}</span> <br/>
				<span class="fieldName">800 Phone:</span> <span class="fieldData">{{ profile.phone800 }}</span> <br/>
				<span class="fieldName">Orders Dept. Phone:</span> <span class="fieldData">{{ profile.phoneOrdersDept }}</span> 
			</p>

			<p>
				<span class="fieldName">Company Email:</span> <span class="fieldData">{{ profile.email }}</span> <br/>
				<span class="fieldName">Orders Dept. Email:</span> <span class="fieldData">{{ profile.emailOrdersDept }}</span> 
			</p>
			
			<p>
				<span class="fieldName">Company Fax:</span> <span class="fieldData">{{ profile.fax }}</span> <br/>
				<span class="fieldName">Orders Dept. Fax:</span> <span class="fieldData">{{ profile.faxOrdersDept }}</span>
			</p>

			<h3>Social Media:</h3>
			
			<a v-bind:href="profile.facebookUrl">
				<img src="@/assets/social/social-fb.png" />
			</a>
			<a v-bind:href="profile.linkedInUrl">
				<img src="@/assets/social/social-link.png" />
			</a>
			<a v-bind:href="'http://www.instagram.com/' + profile.instagramHandle">
				<img src="@/assets/social/social-insta.png" />
			</a>
			<a v-bind:href="profile.twitterHandle">
				<img src="@/assets/social/social-tweet.png" />
			</a>
			<a v-bind:href="profile.youTubeChannel">
				<img src="@/assets/social/social-youtube.png" />
			</a>			

			<p>
				<span class="fieldName">Year Founded:</span> <span class="fieldData">{{ profile.yearFounded }}</span> <br/>
				<span class="fieldName">Member Since:</span> <span class="fieldData"><span>{{profile.memberSince | formatDate}}</span></span>				
			</p>

			<p>
				<span class="fieldName">Region:</span> <span class="fieldData">{{ profile.region }}</span> <br/>
				<span class="fieldName">Territory:</span> <span class="fieldData">{{ profile.territory }}</span> <br/> <br/>
				<span class="fieldName">This Rep Firm Also is also located in the following Regions:</span> <span class="fieldData">{{ profile.additionalRegions }}</span>
			</p>

			<h3>Test Kitchen/Showroom:</h3>
			<p>
				<span class="fieldName">Test Kitchen:</span> <span class="fieldData">{{ profile.testKitchen }}</span> <br/>
				<span class="fieldName">Mobile Test Kitchen:</span> <span class="fieldData">{{ profile.mobileTestKitchen }}</span> <br/>
				<span class="fieldName">Number of Test Kitchens:</span> <span class="fieldData">{{ profile.numberOfTestKitchens }}</span> <br/>
				<span class="fieldName">Test Kitchen Description:</span> <span class="fieldData">{{ profile.testKitchenDescription }}</span> <br/>
				<span class="fieldName">Chef on Staff:</span> <span class="fieldData">{{ profile.chefOnStaff }}</span> <br/>
				<span class="fieldName">Number of Chefs on Staff:</span> <span class="fieldData">{{ profile.numberOfChefsOnStaff }}</span> <br/><br/>

				<span class="fieldName">Showroom:</span> <span class="fieldData">{{ profile.showroom }}</span> <br/>
				<span class="fieldName">Number of Showrooms:</span> <span class="fieldData">{{ profile.numberOfShowrooms }}</span> <br/>
				<span class="fieldName">Number of Showrooms:</span> <span class="fieldData">{{ profile.showroomDescription }}</span>
			</p>

			<h3>Rep Firm Team:</h3>
			<p>
				<span class="fieldName">Total Personnel:</span> <span class="fieldData">{{ profile.totalPersonnel }}</span> <br/>
				<span class="fieldName">Principal(s):</span> <span class="fieldData">{{ profile.principals }}</span> <br/>
				<span class="fieldName">Outside Sales:</span> <span class="fieldData">{{ profile.outsideSales }}</span> <br/>
				<span class="fieldName">Culinary & Sales:</span> <span class="fieldData">{{ profile.culinaryAndSales }}</span> <br/>
				<span class="fieldName">Culinary Only:</span> <span class="fieldData">{{ profile.culinaryOnly }}</span> <br/>
				<span class="fieldName">Inside Sales:</span> <span class="fieldData">{{ profile.insideSales }}</span> <br/>
				<span class="fieldName">Inside Support:</span> <span class="fieldData">{{ profile.insideSupport }}</span>				
			</p>

			<h3>Brands Represented:</h3>
			<p>
				<span class="fieldName">Equipment:</span> <span class="fieldData">{{ profile.brandsRepresented.equipment }}</span> <br/>
				<span class="fieldName">Supplies/Smallwares:</span> <span class="fieldData">{{ profile.brandsRepresented.suppliesSmallwares }}</span> <br/>
				<span class="fieldName">Tabletop:</span> <span class="fieldData">{{ profile.brandsRepresented.tabletop }}</span> <br/>
				<span class="fieldName">Furniture:</span> <span class="fieldData">{{ profile.brandsRepresented.furniture }}</span> <br/>
				<span class="fieldName">Janitorial/Sanitation/Disposables:</span> <span class="fieldData">{{ profile.brandsRepresented.janitorialSanitationDisposables }}</span> <br/>
				<span class="fieldName">Technology:</span> <span class="fieldData">{{ profile.brandsRepresented.technology }}</span> 				
			</p>
			
			<img src="@/assets/profiles/view-line-cards.png" width="170px" />
			<h3>Product Categories Currently Represented:</h3>
			<p>
				<span class="fieldName">Equipment:</span> <span class="fieldData">{{ profile.productCategories.equipment }}</span> <br/>
				<span class="fieldName">Supplies/Smallwares:</span> <span class="fieldData">{{ profile.productCategories.suppliesSmallwares }}</span> <br/>
				<span class="fieldName">Tabletop:</span> <span class="fieldData">{{ profile.productCategories.tabletop }}</span> <br/>
				<span class="fieldName">Furniture:</span> <span class="fieldData">{{ profile.productCategories.furniture }}</span> <br/>
				<span class="fieldName">Janitorial/Sanitation/Disposables:</span> <span class="fieldData">{{ profile.productCategories.janitorialSanitationDisposables }}</span> <br/>
				<span class="fieldName">Technology:</span> <span class="fieldData">{{ profile.productCategories.technology }}</span> 				
			</p>

			<p>
				<span class="fieldName">Market Segment Expertise:</span> <span class="fieldData">{{ profile.marketSegments }}</span> <br/>				
			</p>

			<img src="@/assets/profiles/lines-wanted.png" width="170px" />
			<h3>Looking for New Partners in these Categories:</h3>
			<p>
				<span class="fieldName">Equipment:</span> <span class="fieldData">{{ profile.lookingForPartnersCategories.equipment }}</span> <br/>
				<span class="fieldName">Supplies/Smallwares:</span> <span class="fieldData">{{ profile.lookingForPartnersCategories.suppliesSmallwares }}</span> <br/>
				<span class="fieldName">Tabletop:</span> <span class="fieldData">{{ profile.lookingForPartnersCategories.tabletop }}</span> <br/>
				<span class="fieldName">Furniture:</span> <span class="fieldData">{{ profile.lookingForPartnersCategories.furniture }}</span> <br/>
				<span class="fieldName">Janitorial/Sanitation/Disposables:</span> <span class="fieldData">{{ profile.lookingForPartnersCategories.janitorialSanitationDisposables }}</span> <br/>
				<span class="fieldName">Technology:</span> <span class="fieldData">{{ profile.lookingForPartnersCategories.technology }}</span> 				
			</p>

			<p>
				<span class="fieldName">Looking for New Partners – Contact Person:</span> <span class="fieldData">{{ profile.lookingPartnersPerson }}</span> <br/>
				<span class="fieldName">Looking for New Partners – Contact Email:</span> <span class="fieldData">{{ profile.lookingPartnersEmail }}</span> <br/>
				<span class="fieldName">Looking for New Partners – Contact Phone (Optional):</span> <span class="fieldData">{{ profile.lookingPartnersPhone }}</span> <br/><br/>
				<span class="fieldName">Rep Company Description:</span> <span class="fieldData">{{ profile.repCompanyDescription }}</span> 
			</p>

			<h3>Branch Offices(s):</h3>
			<div v-for="branch in profile.branches" :key="branch.name">
				<span class="fieldData">
				{{ branch.name }}<br/><br/>

			
				{{ branch.branchAddress.address1 }}<br/>
				{{ branch.branchAddress.address2 }}<br/>
				{{ branch.branchAddress.city }}, {{ branch.branchAddress.stateProvince }} {{ branch.branchAddress.zipCode }}, {{ branch.branchAddress.country }}
				<br/><br/>
				</span>	
				
				<span class="fieldName">Branch Phone:</span> <span class="fieldData">IMPLEMENT_ME!</span> <br/>
				<span class="fieldName">Branch Email:</span> <span class="fieldData">{{ branch.email }}</span> <br/>	

				<h4>Branch Test Kitchen/Showroom:</h4>
				<p>
					<span class="fieldName">Test Kitchen:</span> <span class="fieldData">{{ branch.testKitchen }}</span> <br/>
					<span class="fieldName">Mobile Test Kitchen:</span> <span class="fieldData">{{ branch.mobileTestKitchen }}</span> <br/>
					<span class="fieldName">Number of Test Kitchens:</span> <span class="fieldData">{{ branch.numberOfTestKitchens }}</span> <br/>
					<span class="fieldName">Test Kitchen Description:</span> <span class="fieldData">{{ branch.testKitchenDescription }}</span> <br/>
					<span class="fieldName">Chef on Staff:</span> <span class="fieldData">{{ branch.chefOnStaff }}</span> <br/>
					<span class="fieldName">Number of Chefs on Staff:</span> <span class="fieldData">{{ branch.numberOfChefsOnStaff }}</span> <br/><br/>

					<span class="fieldName">Showroom:</span> <span class="fieldData">{{ branch.showroom }}</span> <br/>
					<span class="fieldName">Number of Showrooms:</span> <span class="fieldData">{{ branch.numberOfShowrooms }}</span> <br/>
					<span class="fieldName">Showroom Description:</span> <span class="fieldData">{{ branch.showroomDescription }}</span> <br/>
				</p>

				<span class="fieldName">Branch Office Team:</span> <span class="fieldData">{{ branch.branchOfficeTeam }}</span> <br/>
			</div>
		</div>
    </div>
</template>

<script>

export default {
	name: 'ManufacturerProfile',

	props: {     
        profile: { type: Object, required: true }         
    },

	data() {
		return {			
		};
	},
	
	created() {		
	},

	methods: {
		createElementId(prefix, profileId) {
		return prefix + "-" + profileId;
		}	
	}
	
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

</style>
