<template>
    <span v-if="value && value != ''">
        <span class="fieldName">{{ caption }} </span>
        <span v-if="format=='generic' || format==''">
            <span class="fieldData"><span>{{ value }}</span></span>
        </span>
        <span v-if="format=='phone'">
            <span class="fieldData"><span>{{ value | formatPhone }}</span></span>
        </span>
        <span v-if="format=='semicolon'">
            <span class="fieldData"><span>{{ value | formatSemicolon }}</span></span>
        </span>
        <span v-if="format=='semicolon-url'">
            <a @click="onValueClicked(value)" href="#"><span>{{ value | formatSemicolon }}</span></a>
        </span>
        <span v-if="format=='email'">
            <a :href="`mailto:${value}`">{{ value }}</a>            
        </span>
        <span v-if="format=='url'">
            <a v-bind:href="`${value}`">{{ getPrettyWebsiteUrl(value) }}</a>            
        </span>
        <span v-if="format=='urlNewTab'">
          <router-link :to="{ name: 'value'}" target="_blank">
            <a v-bind:href="`${value}`" target="_blank">{{ getPrettyWebsiteUrl(value) }}</a>
          </router-link>
        </span>
        <span v-if="cr == 'true'"><br/></span>
    </span>
</template>

<script>

export default {
  name: "DataLine",
  data() {
    return {}
  },

  methods: {

    getPrettyWebsiteUrl: function (url) {
      var result = url.replace(/^https?:\/\//, '');
      result = result.replace(/^http?:\/\//, '');
      return result;
    },
    
    onValueClicked(value) {
      this.$emit("onValueClick", value);
    },

    createElementId(prefix, profileId) {
      return prefix + "-" + profileId;
    }
  },

  props: {

    caption: String,

    value: String,

    extraValueStr: String,

    format: {
      type: String,
      default: ''
    },

    cr: {
      type: String,
      default: 'true'
    }
  }
}
</script>

<style>
</style>