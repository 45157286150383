<template>
  <div class="p-field">
    <label v-if="label" :for="id">{{ label }}</label>
    <InputText
      :id="id"
      :value="insideValue"
      :placeholder="placeholder"
      @input="emitInput($event)"
      @keyup.enter="onEnter($event)"
      :class="getDefaultStyling"
    />
  </div>
</template>
<script>
import InputText from "primevue/inputtext";
export default {
  data() {
    return {
      insideValue: "",
      defaultStyling: "p-inputtext-sm",
    };
  },
  computed: {
    getDefaultStyling() {
      return this.styling ? this.styling : this.defaultStyling;
    },
  },
  props: {
    id: { type: String, required: false },
    label: { type: String, required: false },
    placeholder: { type: String, required: false },
    value: { type: String, required: true },
    styling: { type: String, required: false },
  },
  methods: {
    emitInput(e) {
      this.insideValue = e;
      this.$emit("input", this.insideValue);
    },
    onEnter(e) {
       this.$emit("onEnter");
    },
  },
  mounted() {
    this.insideValue = this.value;
  },
  components: {
    InputText,
  },
};
</script>
