import { store } from '../store';

class ChipsService {

onBrandFilterRemove(e, id, arrName){
    // console.log("on brand removed", id);

    let name = arrName;
    if (name === "brandIds") {
        let newArr = store.state.brandFilter.brandIds.filter(
            (x) => x != id
        );
        store.state.brandFilter.brandIds = [...newArr];
    }
        if (name === "mfFirmAttributesIds") {
            let newArr = store.state.brandFilter.mfFirmAttributesIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.mfFirmAttributesIds = [...newArr];
        }
        
        if (name === "brandProductCategoriesIds") {
            let newArr = store.state.brandFilter.brandProductCategoriesIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.brandProductCategoriesIds = [...newArr];
        }
        
        if (name === "regionsNeededCoverageIds") {
            let newArr = store.state.brandFilter.regionsNeededCoverageIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.regionsNeededCoverageIds = [...newArr];
        }

        if (name === "marketSegmentsIds") {
            let newArr = store.state.brandFilter.marketSegmentsIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.marketSegmentsIds = [...newArr];
        }

        if (name === "lookingForNewEquipmentPartnersIds") {
            let newArr = store.state.brandFilter.lookingForNewEquipmentPartnersIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.lookingForNewEquipmentPartnersIds = [...newArr];
        }

        if (name === "lookingForNewSuppliesSmallwaresPartnersIds") {
            let newArr = store.state.brandFilter.lookingForNewSuppliesSmallwaresPartnersIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.lookingForNewSuppliesSmallwaresPartnersIds = [...newArr];
        }

        if (name === "lookingForNewTabletopPartnersIds") {
            let newArr = store.state.brandFilter.lookingForNewTabletopPartnersIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.lookingForNewTabletopPartnersIds = [...newArr];
        }

        if (name === "lookingForNewBrandsPartnersAllIds") {
            let newArr = store.state.brandFilter.lookingForNewBrandsPartnersAllIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.lookingForNewBrandsPartnersAllIds = [...newArr];
        }

        if (name === "lookingForNewFurniturePartnersIds") {
            let newArr = store.state.brandFilter.lookingForNewFurniturePartnersIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.lookingForNewFurniturePartnersIds = [...newArr];
        }
        if (name === "lookingForNewJanSanDispPartnersIds") {
            let newArr = store.state.brandFilter.lookingForNewJanSanDispPartnersIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.lookingForNewJanSanDispPartnersIds = [...newArr];
        }
        if (name === "lookingForNewTechnologyPartnersIds") {
            let newArr = store.state.brandFilter.lookingForNewTechnologyPartnersIds.filter(
                (x) => x != id
            );
            store.state.brandFilter.lookingForNewTechnologyPartnersIds = [...newArr];
        }
}

    onRepFilterRemove(e, id, arrName) {
        // console.log("on rep removed", id);

        let name = arrName;
        if (name === "regionIds") {
            let newArr = store.state.repFilter.regionIds.filter(
                (x) => x != id
            );
            store.state.repFilter.regionIds = [...newArr];
        }
        if (name === "stateIds") {
            let newArr = store.state.repFilter.stateIds.filter(
                (x) => x != id
            );
            store.state.repFilter.stateIds = [...newArr];
        }
        if (name === "lookingForNewRepsPartnersAllIds") {
            let newArr = store.state.repFilter.lookingForNewRepsPartnersAllIds.filter(
                (x) => x != id
            );
            store.state.repFilter.lookingForNewRepsPartnersAllIds = [...newArr];
        }
        if (name === "countryIds") {
            let newArr = store.state.repFilter.countryIds.filter(
                (x) => x != id
            );
            store.state.repFilter.countryIds = [...newArr];
        }
        if (name === "brandIds") {
            let newArr = store.state.repFilter.brandIds.filter(
                (x) => x != id
            );
            store.state.repFilter.brandIds = [...newArr];
        }
        if (name === "productCategoriesIds") {
            let newArr = store.state.repFilter.productCategoriesIds.filter(
                (x) => x != id
            );
            store.state.repFilter.productCategoriesIds = [...newArr];
        }
        if (name === "equipmentCategoriesIds") {
            let newArr = store.state.repFilter.equipmentCategoriesIds.filter(
                (x) => x != id
            );
            store.state.repFilter.equipmentCategoriesIds = [...newArr];
        }
        if (name === "lookingForNewPartnersIds") {
            let newArr = store.state.repFilter.lookingForNewPartnersIds.filter(
                (x) => x != id
            );
            store.state.repFilter.lookingForNewPartnersIds = [...newArr];
        }
        if (name === "tabletopCategoriesIds") {
            let newArr = store.state.repFilter.tabletopCategoriesIds.filter(
                (x) => x != id
            );
            store.state.repFilter.tabletopCategoriesIds = [...newArr];
        }
        if (name === "furnitureCategoriesIds") {
            let newArr = store.state.repFilter.furnitureCategoriesIds.filter(
                (x) => x != id
            );
            store.state.repFilter.furnitureCategoriesIds = [...newArr];
        }
        if (name === "janSanDispCategoriesIds") {
            let newArr = store.state.repFilter.janSanDispCategoriesIds.filter(
                (x) => x != id
            );
            store.state.repFilter.janSanDispCategoriesIds = [...newArr];
        }
        if (name === "technologyCategoriesIds") {
            let newArr = store.state.repFilter.technologyCategoriesIds.filter(
                (x) => x != id
            );
            store.state.repFilter.technologyCategoriesIds = [...newArr];
        }
        if (name === "lookingForNewEquipmentPartnersIds") {
            let newArr = store.state.repFilter.lookingForNewEquipmentPartnersIds.filter(
                (x) => x != id
            );
            store.state.repFilter.lookingForNewEquipmentPartnersIds = [...newArr];
        }
        if (name === "lookingForNewSuppliesSmallwaresPartnersIds") {
            let newArr = store.state.repFilter.lookingForNewSuppliesSmallwaresPartnersIds.filter(
                (x) => x != id
            );
            store.state.repFilter.lookingForNewSuppliesSmallwaresPartnersIds = [...newArr];
        }
        if (name === "lookingForNewTabletopPartnersIds") {
            let newArr = store.state.repFilter.lookingForNewTabletopPartnersIds.filter(
                (x) => x != id
            );
            store.state.repFilter.lookingForNewTabletopPartnersIds = [...newArr];
        }
        if (name === "lookingForNewFurniturePartnersIds") {
            let newArr = store.state.repFilter.lookingForNewFurniturePartnersIds.filter(
                (x) => x != id
            );
            store.state.repFilter.lookingForNewFurniturePartnersIds = [...newArr];
        }
        if (name === "lookingForNewJanSanDispPartnersIds") {
            let newArr = store.state.repFilter.lookingForNewJanSanDispPartnersIds.filter(
                (x) => x != id
            );
            store.state.repFilter.lookingForNewJanSanDispPartnersIds = [...newArr];
        }
        if (name === "lookingForNewTechnologyPartnersIds") {
            let newArr = store.state.repFilter.lookingForNewTechnologyPartnersIds.filter(
                (x) => x != id
            );
            store.state.repFilter.lookingForNewTechnologyPartnersIds = [...newArr];
        }
        if (name === "repFirmAttributesIds") {
            let newArr = store.state.repFilter.repFirmAttributesIds.filter(
                (x) => x != id
            );
            store.state.repFilter.repFirmAttributesIds = [...newArr];
        }
        if (name === "suppliesSmallwaresIds") {
            let newArr = store.state.repFilter.suppliesSmallwaresIds.filter(
                (x) => x != id
            );
            store.state.repFilter.suppliesSmallwaresIds = [...newArr];
        }
    }

    getRepName(id, name){

        // console.log('Chips asquired for name=' + name);

        if (name === "regionIds") {
            return store.state.regions.find((x) => x.id === id)?.name;
          }
          if (name === "stateIds") {
            return store.state.states.find((x) => x.id === id).name;
          }
          if (name === "countryIds") {
            return store.state.countries.find((x) => x.id === id).name;
          }
          if (name === "brandIds") {
            return store.state.brands.find((x) => x.id === id).name;
          }
          if (name === "productCategoriesIds") {
            return store.state.productCategories.find((x) => x.id === id)
              .name;
          }
          if (name === "brandProductCategoriesIds") {
            return store.state.brandProductCategories.find((x) => x.id === id)
              .name;
          }
          if (name === "equipmentCategoriesIds") {
            return store.state.equipmentCategories.find((x) => x.id === id)
              .name;
          }
          if (name === "lookingForNewPartnersIds") {
            return store.state.lookingForNewPartners.find((x) => x.id === id)
              .name;
          }
          if (name === "repFirmAttributesIds") {
            return store.state.repFirmAttributes.find((x) => x.id === id)
              .name;
          }
          if (name === "suppliesSmallwaresCategoriesIds") {
            return store.state.suppliesSmallwaresCategories.find((x) => x.id === id)
              .name;
          }
          if (name === "tabletopCategoriesIds") {
            return store.state.tabletopCategories.find((x) => x.id === id)
              .name;
          }
          if (name === "furnitureCategoriesIds") {
            return store.state.furnitureCategories.find((x) => x.id === id)
              .name;
          }
          if (name === "janSanDispCategoriesIds") {
            return store.state.janSanDispCategories.find((x) => x.id === id)
              .name;
          }
          if (name === "technologyCategoriesIds") {
            return store.state.technologyCategories.find((x) => x.id === id)
              .name;
          }
          if (name === "lookingForNewEquipmentPartnersIds") {
            return store.state.lookingForNewEquipmentPartners.find(
              (x) => x.id === id
            ).name;
          }
          if (name === "lookingForNewSuppliesSmallwaresPartnersIds") {
            return store.state.lookingForNewSuppliesSmallwaresPartners.find(
              (x) => x.id === id
            ).name;
          }
          if (name === "lookingForNewTabletopPartnersIds") {
            return store.state.lookingForNewTabletopPartners.find(
              (x) => x.id === id
            ).name;
          }
          if (name === "lookingForNewFurniturePartnersIds") {
            return store.state.lookingForNewFurniturePartners.find(
              (x) => x.id === id
            ).name;
          }
          if (name === "lookingForNewJanSanDispPartnersIds") {
            return store.state.lookingForNewJanSanDispPartners.find(
              (x) => x.id === id
            ).name;
          }
          if (name === "lookingForNewTechnologyPartnersIds") {
            return store.state.lookingForNewTechnologyPartners.find(
              (x) => x.id === id
            ).name;
          }
          if (name === "mfFirmAttributesIds") {
            return store.state.mfFirmAttributes.find(
              (x) => x.id === id
            ).name;
          }
          if (name === "regionsNeededCoverageIds") {
            return store.state.regionsNeededCoverage.find(
              (x) => x.id === id
            )?.name;
          }
          if (name === "lookingForNewBrandsPartnersAllIds") {
            return store.state.lookingForNewBrandsPartnersAll.find(
              (x) => x.id === id
            )?.name;
          }
          if (name === "lookingForNewRepsPartnersAllIds") {
            return store.state.lookingForNewRepsPartnersAll.find(
              (x) => x.id === id
            ).name;
          }
          if (name === "marketSegmentsIds") {
            return store.state.marketSegments.find(
              (x) => x.id === id
            ).name;
          }
          // mfFirmAttributesIds
          //regionsNeededCoverageIds
          //marketSegmentsIds
    }

    
}

export let chipsService = new ChipsService();