<template>
  <div id="app">
    <Loader />
    <MainLayout>
      <router-view></router-view>
    </MainLayout>
  </div>
</template>

<script>
import Loader from "./components/Loader.vue";
import MainLayout from "./layouts/MainLayout.vue";

export default {
  data() {
    return {
    };
  },
  created() {

  },
  components: {
    Loader,
    MainLayout,
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css?family=Poppins");
#app {
  font-family: "Poppins", "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}

body {
  font-family: "Poppins", sans-serif;
}

h2 {
  font-size: 24px;
}


html,
body {
  padding: 0;
  margin: 0;
  line-height: 24px;
  font-size: 16px;
}
.flexcontainer {
  display: flex;
  flex-direction: row;
  min-height: 600px;
  align-items: stretch;
}
.flexitem {
  display: flex;
}
/* #canvas-left {
  order: -1;
  flex: 0 0 300px;
} */
#content {
  order: 1;
  padding: 1rem;
  width: 100%;
}
#content p {
  display: block;
  line-height: 24px;
}
#canvas-right {
  order: 2;
  flex: 0 0 57px;
}
.fixed {
  position: fixed;
  width: 300px;
}
.fieldName {
  font-weight: 900;
  color: #343a40;
}
.fieldData {
  color: #2c3e50;
}
.virtual-button-link {
  background: none;
  border: none;
  color: blue;
  text-decoration: underline;
  cursor: pointer;
  padding: 0;
}
.virtual-button-link:hover {
  text-decoration: none;
}
</style>
