import Vue from "vue";
import VueRouter from "vue-router";
import SearchPage from "../components/SearchPage.vue";
import SearchForm from "../components/SearchForm.vue";
import Loader from '../components/Loader.vue';
import Callback from '../components/Callback.vue';
import { ssoService } from '../services/ssoService';
import {MF_LOOKING, REPS_LOOKING} from "@/common/global";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name:'home',
    component: SearchPage,
  },
  {
    path: MF_LOOKING,
    name:'mf',
    component: SearchPage,
  },
  {
    path: REPS_LOOKING,
    name:'reps',
    component: SearchPage,
  },
  {
    path: "/callback",
    name:'callback',
    component: Callback,
  }
];

const router = new VueRouter({
  mode: "history",
  //base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
 let isCallback = to.path.indexOf('callback') > -1;
  if(isCallback){
    next();
  }
  else if(to.path.includes(MF_LOOKING) || to.path.includes(REPS_LOOKING) ){
      console.log("BEFORE---LOOKING");
      console.log("TO", to);
      console.log("FROM", from);
      console.log("TO ---> path", to.path);
      localStorage.setItem('route-path', to.path)
      next();
  }
  else if(ssoService.isAuthenticated()) {
    next();
  }
  else {
    ssoService.login();
  }
  

})

export default router;
