<template>
  <div class="main-container">
    <div class="header">
      <Header />
    </div>
    <div class="content">
    <slot></slot>
    </div>
    <div class="footer">
      <Footer />
    </div>
  </div>
</template>

<script>
import Header from "../components/LayoutParts/Header";
import Footer from "../components/LayoutParts/Footer";

export default {
  data() {
    return {};
  },
  components: {
    Header,
    Footer,
  },
};
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: column;
}
</style>