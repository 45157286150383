<template>
  <div class="form-layout">
    <div class="p-fluid">
      <div v-if="caption" class="caption" @click="hide()">
        <span>{{ caption }}</span>
      </div>
      <template v-if="show">
        <slot></slot>
      </template>
      <hr class="line" v-if="bottomLine"/>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      show: false,
    };
  },
  props: {
    caption: {type: String, required: false},
    bottomLine: {type: Boolean, required: false},
    showContent: {type: Boolean, required: false}
  },
  methods: {
    hide() {
      this.show = !this.show;
    },
  },
  mounted() {
    this.show = this.showContent;
  },
  watch: {
    showContent(newVal) {
      this.show = newVal;
    }
  },
  components: {},
};
</script>

<style scoped>
.line {
  margin-bottom: 10px;
}

.caption {
  margin-bottom: 5px;
}

.caption > span {
  text-align: left;
  color: #3e77aa;
  cursor: pointer;
  box-sizing: border-box;
  padding: 0;
  border: 0;
  vertical-align: baseline;
  margin: 0 16px 0 0;
}
</style>