import { httpService } from "./httpService";
import axios from 'axios';
import { store } from '../store';

class SsoService {
  _callback;
  _baseUrl;
  _clientId;
  _clientSecret;
  constructor() {
    this.callback = 'https://mafsi-locator.org/callback';
    this._baseUrl = 'https://member.mafsi.org/';
    this._clientId = '9beda3ea-2ed2-4c15-a2c3-e418a2cdcfd1';
    this._clientSecret = 'NJRmhJNsSRAIec6iBhMWQqRke3ZkpuFpha8QUrvIWqfJMmjMF2Q6ubZUKkU3AdoIJzzpicDV8pvGhGZIRzer1w==';
  }

  config() {
    httpService
      .getBase(this._baseUrl,
        ".well-known/openid-configuration"
      )
      .then(
        (response) => {
        },
        (error) => console.log("auth error", error)
      );
  }

  login() {
    return httpService
      .getBase(this._baseUrl,
        `oauth/authorize?client_id=${this._clientId}&redirect_uri=${this.callback}&scope=openid&response_type=token id_token`
      )
      .then(
        (response) => {
          window.location.href = response.config.url;
        },
        (error) => console.log("auth error", error)
      );
  }

logout(){
  window.location = `${this._baseUrl}/log-out`;
}

  isAuthenticated() {
    // use it for development
    // return true;

    // use it for deployment on production
    return store.state.authInfo.isAuth;
  }

  handleAuth() {
    let token = this.getAccessToken();
    return new Promise((resolve, reject) => {
      return axios.get(`${this._baseUrl}oauth/userinfo?client_id=${this._clientId}&client_secret=${this._clientSecret}&token_endpoint_auth_method=client_secret_basic`, {
        headers: {
          Authorization: 'Bearer ' + token
        }
      })
        .then(
          (response) => {
            store.state.authInfo.isAuth = true;
            store.state.authInfo.userName = response.data.name;
            resolve();
          },
          (error) => {
            this.logout();
            reject();
          }
        );
    })
  }

  getAccessToken() {
    return localStorage.getItem('token')
  }

}

export let ssoService = new SsoService();
