<template>
  <div class="content">    
      <div
        v-for="profile in profiles"
        :key="profile.uniqueId"
        class="profile-section"
      >
        <div v-if="profile.profileType == 'Rep'">
            <CompanyProfile v-bind:profile="profile"></CompanyProfile>          
        </div>
        <div v-if="profile.profileType == 'Brand'">
            <BrandProfile @searchEvent="searchByRep($event)" @onEquipmentValueClick="searchByEquipment($event)" v-bind:profile="profile"></BrandProfile>
        </div>
        <div v-if="profile.profileType == 'Manufacturer'">
            <ManufacturerProfile v-bind:profile="profile"></ManufacturerProfile>
        </div>    
      </div>
  </div>
</template>

<script>

export default {
  props: {
    profiles: {type: Array, required: true }
  },

  data() {
    return {
    };
  },
  methods: {
    searchByRep(e){
      this.$emit('searchByRep', e);
    },
    searchByEquipment(value){
      this.$emit("searchByEquipmant", value);
    }
  },
};
</script>


<style scoped>
  
.profile-section {
  text-align: left;
  padding: 2em 5em 2em 5em;
  font-size: 80%;
  border-bottom: 3px dotted gray;
  width: 90%;
}

.content {
  padding: auto;
}
</style>