<template>
  <div class="rep-filtration">
    <FormLayout :showContent="true">
      <LabeledInput
        label="Company Name"
        id="rep-company-search"
        placeholder="Company Name"
        v-model="companyName"
        @onEnter="onEnter()"
      />
    </FormLayout>
    <br />
    <FormLayout :showContent="true">
      <LabeledInput
        label="ZIP code"
        id="zip-value-search"
        placeholder="ZIP value"
        v-model="zipValue"
        @onEnter="onEnter()"
      />
    </FormLayout>
    <!--
    <FormLayout>
      <div class="p-field">
        <label for="search-input-1">Search Input 1</label>
        <InputText
          id="search-input-1"
          v-model="searchInput1"
          placeholder="Search Input 1"
          class="p-inputtext-sm"
        />
      </div>
    </FormLayout>
    <FormLayout>
      <div class="p-field">
        <label for="search-input-2">Search Input 2</label>
        <InputText
          id="search-input-2"
          v-model="searchInput2"
          placeholder="Search Input 2"
          class="p-inputtext-sm"
        />
      </div>
    </FormLayout>
    -->
    <br />
    <FormLayout :show-content="showRegionContent" :bottomLine="true" :caption="regionCaption">
      <MultipleCheckbox
        :values="$store.state.regions"
        v-model="regionIds"
        :alpIndex="false"
        :height="190"
        :key="$store.getters.changeTest + regionCaption"
        :matchAll="$store.state.repFilter.regionIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'regionIds')"
      />
    </FormLayout>
    <FormLayout :bottomLine="true" :caption="stateCaption">
      <MultipleCheckbox
        :values="$store.state.states"
        v-model="stateIds"
        :alpIndex="false"
        :key="$store.getters.changeTest"
        :height="190"
        :matchAll="$store.state.repFilter.stateIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'stateIds')"
      />
    </FormLayout>
    <FormLayout :bottomLine="true" :caption="countryCaption">
      <MultipleCheckbox
        :values="$store.state.countries"
        v-model="countryIds"
        :alpIndex="false"
        :key="$store.getters.changeTest"
        :matchAll="$store.state.repFilter.countryIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'countryIds')"
      />
    </FormLayout>
    <FormLayout :bottomLine="true" :caption="brandCaption">
      <MultipleCheckbox
        :values="$store.state.brands"
        v-model="brandIds"
        :alpIndex="true"
        :searchable="true"
        :height="210"
        :key="$store.getters.changeTest"
        :matchAll="$store.state.repFilter.brandIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'brandIds')"
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="productCategoriesCaption">
      <MultipleCheckbox
        :values="$store.state.productCategories"
        v-model="productCategoriesIds"
        :alpIndex="false"
        :searchable="false"
        :height="130"
        :key="$store.getters.changeTest"
        :matchAllVisible=true
        :matchAll="$store.state.repFilter.productCategoriesIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'productCategoriesIds')"
      />
    </FormLayout>


    <FormLayout :bottomLine="true" :caption="equipmentCategoriesCaption">
      <MultipleCheckbox
        :values="$store.state.equipmentCategories"
        v-model="equipmentCategoriesIds"
        :alpIndex="false"
        :searchable="false"
        :height="350"
        :key="$store.getters.equipmentCategories"
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="suppliesSmallwaresCaption">
      <MultipleCheckbox
        :values="$store.state.suppliesSmallwaresCategories"
        v-model="suppliesSmallwaresCategoriesIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeTest"
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="tabletopCategoriesCaption">
      <MultipleCheckbox
        :values="$store.state.tabletopCategories"
        v-model="tabletopCategoriesIds"
        :alpIndex="false"
        :searchable="false"
        :height="170"
        :key="$store.getters.changeTest"
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="furnitureCategoriesCaption">
      <MultipleCheckbox
        :values="$store.state.furnitureCategories"
        v-model="furnitureCategoriesIds"
        :alpIndex="false"
        :searchable="false"
        :height="170"
        :key="$store.getters.changeTest"
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="janSanDispCategoriesCaption">
      <MultipleCheckbox
        :values="$store.state.janSanDispCategories"
        v-model="janSanDispCategoriesIds"
        :alpIndex="false"
        :searchable="false"
        :height="170"
        :key="$store.getters.changeTest"
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="technologyCategoriesCaption">
      <MultipleCheckbox
        :values="$store.state.technologyCategories"
        v-model="technologyCategoriesIds"
        :alpIndex="false"
        :searchable="false"
        :height="100"
        :key="$store.getters.changeTest"
      />
    </FormLayout>

    <FormLayout :show-content="lookingForNewRepsPartnersAllIds.length > 0" :bottomLine="true" :caption="lookingForNewRepsPartnersAllCaption">
      <MultipleCheckbox
        :values="$store.state.lookingForNewRepsPartnersAll"
        v-model="lookingForNewRepsPartnersAllIds"
        :alpIndex="false"
        :searchable="false"
        :height="25"
        :key="NaN"
        :matchAllVisible=false
        @matchAllChanged="matchChanged($event, 'lookingForNewRepsPartnersAllIds')"
      />
    </FormLayout>


    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewEquipmentPartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewEquipmentPartners"
        v-model="lookingForNewEquipmentPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeTest"
        :matchAllVisible=true
        :matchAll="
          $store.state.repFilter.lookingForNewEquipmentPartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewEquipmentPartnersIds')
        "
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewSuppliesSmallwaresPartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewSuppliesSmallwaresPartners"
        v-model="lookingForNewSuppliesSmallwaresPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeTest"
        :matchAllVisible=true
        :matchAll="
          $store.state.repFilter
            .lookingForNewSuppliesSmallwaresPartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewSuppliesSmallwaresPartnersIds')
        "
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewTabletopPartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewTabletopPartners"
        v-model="lookingForNewTabletopPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="170"
        :key="$store.getters.changeTest"
        :matchAllVisible=true
        :matchAll="
          $store.state.repFilter.lookingForNewTabletopPartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewTabletopPartnersIds')
        "
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewFurniturePartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewFurniturePartners"
        v-model="lookingForNewFurniturePartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="150"
        :key="$store.getters.changeTest"
        :matchAllVisible=true
        :matchAll="
          $store.state.repFilter.lookingForNewFurniturePartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewFurniturePartnersIds')
        "
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewJanSanDispPartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewJanSanDispPartners"
        v-model="lookingForNewJanSanDispPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="170"
        :key="$store.getters.changeTest"
        :matchAllVisible=true
        :matchAll="
          $store.state.repFilter.lookingForNewJanSanDispPartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewJanSanDispPartnersIds')
        "
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewTechnologyPartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewTechnologyPartners"
        v-model="lookingForNewTechnologyPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="70"
        :key="$store.getters.changeTest"
        :matchAllVisible=true
        :matchAll="
          $store.state.repFilter.lookingForNewTechnologyPartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewTechnologyPartnersIds')
        "
      />
    </FormLayout>
    <!--
    <FormLayout :bottomLine="true" :caption="lookingForNewPartnersCaption">
      <MultipleCheckbox
        :values="$store.state.lookingForNewPartners"
        v-model="lookingForNewPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="140"
        :key="$store.getters.lookingForNewPartners"
      />
    </FormLayout>
    -->
    <FormLayout :bottomLine="true" :caption="totalPersonnelCaption">
      <div class="slider">
        <div class="range">{{ totalPersonnel[0] }}-{{ totalPersonnel[1] }}</div>
        <div>
          <Slider v-model="totalPersonnel" :range="true" />
        </div>
      </div>
    </FormLayout>
    <FormLayout :bottomLine="true" :caption="marketSegmentsCaption">
      <MultipleCheckbox
        :values="$store.state.marketSegments"
        v-model="marketSegmentsIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeTest"
        :matchAllVisible=true
        :matchAll="$store.state.repFilter.marketSegmentsIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'marketSegmentsIds')"
      />
    </FormLayout>
    <FormLayout :bottomLine="true" :caption="repFirmAttrCaption">
      <MultipleCheckbox
        :values="$store.state.repFirmAttributes"
        v-model="repFirmAttributesIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeTest"
        :matchAll="$store.state.repFilter.repFirmAttributesIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'repFirmAttributesIds')"
      />
    </FormLayout>
  </div>
</template>

<script>
import LabeledInput from "../common/LabeledInput";
import Slider from "primevue/slider";
import MultipleCheckbox from "../common/MultipleCheckbox";
import FormLayout from "../common/FormLayout";

export default {
  data() {
    return {};
  },
  computed: {
    regionCaption() {
      return `Regions (${this.$store.state.regions.length})`;
    },
    showRegionContent() {
      return this.regionIds.length > 0;
    },
    stateCaption() {
      return `State(s)/Province(s) in Territory (${this.$store.state.states.length})`;
    },
    countryCaption() {
      return `Countries (${this.$store.state.countries.length})`;
    },
    brandCaption() {
      return `Brands (${this.$store.state.brands.length})`;
    },
    productCategoriesCaption() {
      return `Product Categories - Overall (${this.$store.state.productCategories.length})`;
    },
    equipmentCategoriesCaption() {
      return `Product Categories - Equipment (${this.$store.state.equipmentCategories.length})`;
    },
    suppliesSmallwaresCaption() {
      return `Product Categories - Supplies (${this.$store.state.suppliesSmallwaresCategories.length})`;
    },
    tabletopCategoriesCaption() {
      return `Product Categories - Tabletop (${this.$store.state.tabletopCategories.length})`;
    },
    furnitureCategoriesCaption() {
      return `Product Categories - Furniture (${this.$store.state.furnitureCategories.length})`;
    },
    janSanDispCategoriesCaption() {
      return `Product Categories - Jan/San/Disp (${this.$store.state.janSanDispCategories.length})`;
    },
    technologyCategoriesCaption() {
      return `Product Categories - Technology (${this.$store.state.technologyCategories.length})`;
    },

    lookingForNewRepsPartnersAllCaption() {
      return `Looking for New Partners - All`;
    },
    lookingForNewPartnersCaption() {
      return `Looking For New Partners (${this.$store.state.lookingForNewPartners.length})`;
    },
    lookingForNewEquipmentPartnersCaption() {
      return `Looking for New Partners - Equipment (${this.$store.state.lookingForNewEquipmentPartners.length})`;
    },
    lookingForNewSuppliesSmallwaresPartnersCaption() {
      return `Looking for New Partners - Supplies (${this.$store.state.lookingForNewSuppliesSmallwaresPartners.length})`;
    },
    lookingForNewTabletopPartnersCaption() {
      return `Looking for New Partners - Tabletop (${this.$store.state.lookingForNewTabletopPartners.length})`;
    },
    lookingForNewFurniturePartnersCaption() {
      return `Looking for New Partners - Furniture (${this.$store.state.lookingForNewFurniturePartners.length})`;
    },
    lookingForNewJanSanDispPartnersCaption() {
      return `Looking for New Partners - Jan/San/Disp (${this.$store.state.lookingForNewJanSanDispPartners.length})`;
    },
    lookingForNewTechnologyPartnersCaption() {
      return `Looking for New Partners - Technology (${this.$store.state.lookingForNewTechnologyPartners.length})`;
    },
    totalPersonnelCaption() {
      return "Total Personnel";
    },
    marketSegmentsCaption() {
      return `Market Segments (${this.$store.state.marketSegments.length})`;
    },
    repFirmAttrCaption() {
      return `Rep Firm Attributes (${this.$store.state.repFirmAttributes.length})`;
    },
    companyName: {
      get() {
        return this.$store.state.repFilter.companyName;
      },
      set(val) {
        this.$store.state.repFilter.companyName = val;
      },
    },
    zipValue: {
      get() {
        return this.$store.state.repFilter.zipValue;
      },
      set(val) {
        this.$store.state.repFilter.zipValue = val;
      },
    },
    stateIds: {
      get() {
        return this.$store.state.repFilter.stateIds;
      },
      set(val) {
        this.$store.state.repFilter.stateIds = val;
      },
    },
    regionIds: {
      get() {
        return this.$store.state.repFilter.regionIds;
      },
      set(val) {
        this.$store.state.repFilter.regionIds = val;
      },
    },
    countryIds: {
      get() {
        return this.$store.state.repFilter.countryIds;
      },
      set(val) {
        this.$store.state.repFilter.countryIds = val;
      },
    },
    brandIds: {
      get() {
        return this.$store.state.repFilter.brandIds;
      },
      set(val) {
        this.$store.state.repFilter.brandIds = val;
      },
    },

    // Product categories block
    productCategoriesIds: {
      get() {
        return this.$store.state.repFilter.productCategoriesIds;
      },
      set(val) {
        this.$store.state.repFilter.productCategoriesIds = val;
      },
    },

    equipmentCategoriesIds: {
      get() {
        return this.$store.state.repFilter.equipmentCategoriesIds;
      },
      set(val) {
        this.$store.state.repFilter.equipmentCategoriesIds = val;
      },
    },
    suppliesSmallwaresCategoriesIds: {
      get() {
        return this.$store.state.repFilter.suppliesSmallwaresCategoriesIds;
      },
      set(val) {
        this.$store.state.repFilter.suppliesSmallwaresCategoriesIds = val;
      },
    },
    tabletopCategoriesIds: {
      get() {
        return this.$store.state.repFilter.tabletopCategoriesIds;
      },
      set(val) {
        this.$store.state.repFilter.tabletopCategoriesIds = val;
      },
    },
    furnitureCategoriesIds: {
      get() {
        return this.$store.state.repFilter.furnitureCategoriesIds;
      },
      set(val) {
        this.$store.state.repFilter.furnitureCategoriesIds = val;
      },
    },
    janSanDispCategoriesIds: {
      get() {
        return this.$store.state.repFilter.janSanDispCategoriesIds;
      },
      set(val) {
        this.$store.state.repFilter.janSanDispCategoriesIds = val;
      },
    },
    technologyCategoriesIds: {
      get() {
        return this.$store.state.repFilter.technologyCategoriesIds;
      },
      set(val) {
        this.$store.state.repFilter.technologyCategoriesIds = val;
      },
    },


    repFirmAttributesIds: {
      get() {
        return this.$store.state.repFilter.repFirmAttributesIds;
      },
      set(val) {
        this.$store.state.repFilter.repFirmAttributesIds = val;
      },
    },

    lookingForNewPartnersIds: {
      get() {
        return this.$store.state.repFilter.lookingForNewPartnersIds;
      },
      set(val) {
        this.$store.state.repFilter.lookingForNewPartnersIds = val;
      },
    },
    lookingForNewRepsPartnersAllIds: {
      get() {
        return this.$store.state.repFilter.lookingForNewRepsPartnersAllIds;
      },
      set(val) {
        this.$store.state.repFilter.lookingForNewRepsPartnersAllIds = val;
      },
    },
    lookingForNewEquipmentPartnersIds: {
      get() {
        return this.$store.state.repFilter.lookingForNewEquipmentPartnersIds;
      },
      set(val) {
        this.$store.state.repFilter.lookingForNewEquipmentPartnersIds = val;
      },
    },
    lookingForNewSuppliesSmallwaresPartnersIds: {
      get() {
        return this.$store.state.repFilter
          .lookingForNewSuppliesSmallwaresPartnersIds;
      },
      set(val) {
        this.$store.state.repFilter.lookingForNewSuppliesSmallwaresPartnersIds = val;
      },
    },
    lookingForNewTabletopPartnersIds: {
      get() {
        return this.$store.state.repFilter.lookingForNewTabletopPartnersIds;
      },
      set(val) {
        this.$store.state.repFilter.lookingForNewTabletopPartnersIds = val;
      },
    },
    lookingForNewFurniturePartnersIds: {
      get() {
        return this.$store.state.repFilter.lookingForNewFurniturePartnersIds;
      },
      set(val) {
        this.$store.state.repFilter.lookingForNewFurniturePartnersIds = val;
      },
    },
    lookingForNewJanSanDispPartnersIds: {
      get() {
        return this.$store.state.repFilter.lookingForNewJanSanDispPartnersIds;
      },
      set(val) {
        this.$store.state.repFilter.lookingForNewJanSanDispPartnersIds = val;
      },
    },
    lookingForNewTechnologyPartnersIds: {
      get() {
        return this.$store.state.repFilter.lookingForNewTechnologyPartnersIds;
      },
      set(val) {
        this.$store.state.repFilter.lookingForNewTechnologyPartnersIds = val;
      },
    },
    marketSegmentsIds: {
      get() {
        return this.$store.state.repFilter.marketSegmentsIds;
      },
      set(val) {
        this.$store.state.repFilter.marketSegmentsIds = val;
      },
    },
    totalPersonnel: {
      get() {
        return this.$store.state.repFilter.totalPersonnel;
      },
      set(val) {
        this.$store.state.repFilter.totalPersonnel = val;
      },
    },
    repFirmIds: {
      get() {
        return this.$store.state.repFilter.repFirmIds;
      },
      set(val) {
        this.$store.state.repFilter.repFirmIds = val;
      },
    },
  },
  methods: {
    matchChanged(e, name) {
      this.$store.state.repFilter[`${name}MatchAll`] = e;
    },
    onEnter() {
      this.$emit('onEnter');
    },
  },
  components: {
    LabeledInput,
    MultipleCheckbox,
    Slider,
    FormLayout,
  },
};
</script>

<style scoped>
.rep-filtration .slider {
  margin-bottom: 15px;
}
.rep-filtration .slider .range {
  margin-bottom: 8px;
}
</style>
