<template>
  <div class="header">
    <div class="user-info">
      <span class="name"> {{ userName }} </span>
      <span v-if="userName" @click="logout()" class="logout"> Logout </span>
    </div>
    <a href="https://www.mafsi.org" class="navbar__logo-wrapper">
      <img src="@/assets/mafsi-new-logo-white.png" class="logo" width="350px" />
    </a>
    <div class="menu-wrapper">
      <ul class="header-menu">
        <li>
          <a href="https://www.mafsi.org" target="_blank">MAFSI Main Site</a>
        </li>
        <li>
          <a href="https://member.mafsi.org/member-compass" target="_blank"
            >Member Compass (Update your Profile)</a
          >
        </li>
        <li>
          <a href="https://member.mafsi.org/region-map" target="_blank"
            >Region Map</a
          >
        </li>
        <li>
          <a href="https://member.mafsi.org/associate-members" target="_blank"
            >Associate Locator</a
          >
        </li>
        <li>
          <a
            href="https://member.mafsi.org/networking-directory-mafsi-members"
            target="_blank"
            >Networking Directory</a
          >
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import { ssoService } from "../../services/ssoService";
export default {
  data() {
    return {};
  },
  computed: {
    userName() {
      return this.$store.state.authInfo.userName;
    },
  },
  methods: {
    logout() {
      ssoService.logout();
    },
  },
};
</script>

<style scoped>
.header {
  border: 1px solid black;
  height: 200px;
  width: 100%;
  clear: both;
  background-color: black;
  color: white;
}

.header a {
  color: white;
}

.header-menu {
  display: block;
  padding-top: 5em;
}

.header-menu ul {
  color: white;
}

.header-menu li {
  display: block;
  float: left;
  margin-right: 3em;
  text-decoration: none;
}

.navbar__logo-wrapper {
  display: block;
  float: left;
  margin-right: 10em;
}

.menu-wrapper {
  display: block;
}
.navbar__logo-wrapper {
  display: block;
  float: left;
  margin-right: 5em;
}

.logo {
  padding: 2em;
}

.user-info {
  display: flex;
  justify-content: flex-end;
}

.user-info .logout {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  text-align: right;
  box-sizing: border-box;
  font: normal 1rem Circular, sans-serif;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
  letter-spacing: 0;
  font-size: inherit;
  font-style: italic;
  text-transform: lowercase;
  margin-left: 5px;
  float: none;
  color: #efefef;
  margin-top: 0;
  margin-left: 15px;
  margin-right: 8px;
  text-decoration: none;
}

.user-info .logout:hover {
  text-decoration: underline;
}

.user-info .name {
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  text-align: right;
  cursor: pointer;
  box-sizing: border-box;
  font: normal 1rem Circular, sans-serif;
  line-height: 24px;
  font-weight: 700;
  text-decoration: none;
  text-transform: none;
  letter-spacing: 0;
  color: #fff;
}
</style>