<template>
  <div class="search-page">
    <div class="filters">
      <Search
          @searchEvent="getData($event)"
          @resetForm="resetForm($event)"
          @change="changeTab($event)"
          @exportCsvReport="exportReport($event)"
          :key="searchRefresh"
          @onEnter="onEnter()"
          ref="topPagination"
      ></Search>
    </div>
    <div class="content mainContainer">
      <SelectedChips
          :filter="getFilter"
          :key="getKey + chipsKey"
      />
      <div>
        <Paginator
            v-if="feed.items.length > 0 && feed.totalPages > 1"
            :pageCount="feed.totalPages"
            :prev-text="'Prev'"
            :next-text="'Next'"
            :click-handler="onPageTop"
            :container-class="'pagination'"
            :page-class="'page-item'"
            :page-count="3"
            :page-range="10"
            ref="topPagination"
        ></Paginator>
      </div>

      <span class="records-counter">Records count: {{ feed.totalItems }}</span>
      <ProfilesList @searchByRep="searchByRep($event)"
                    @searchByEquipmant="searchByEquipment($event)"
                    :profiles="feed.items"/>
      <Paginator
          v-if="feed.items.length > 0 && feed.totalPages > 1"
          :pageCount="feed.totalPages"
          :prev-text="'Prev'"
          :next-text="'Next'"
          :click-handler="onPageBottom"
          :container-class="'pagination'"
          :page-class="'page-item'"
          :page-count="3"
          :page-range="10"
          ref="bottomPagination"
      ></Paginator>
    </div>
  </div>
</template>

<script>
import Paginator from "../components/common/Paginator";
import Search from "./Filtration/Search";
import {searchPageService} from "../services/searchService";
import SelectedChips from "./Filtration/SelectedChips";
import {removeBordersFromAllLogos} from '../services/imageTrimService';
import {codeToGuidMapper} from "@/mappers/code-to-region";
import {MF_LOOKING, REPS_LOOKING} from "@/common/global";

export default {
  name: "SearchPage",
  data() {
    return {
      searchRefresh: 0,
      feed: {
        totalPages: 0,
        totalItems: 0,
        items: [],
        curentPage: 0,
      },
      list: [],
      chipsKey: ""
    };
  },
  computed: {
    getFilter() {
      return this.$store.state.selectedFilter === 0 ? this.$store.state.repFilter : this.$store.state.brandFilter;
    },
    getKey() {
      return this.$store.state.selectedFilter === 0 ? this.$store.getters.changeTest : this.$store.getters.changeBrand;
    },
    showRepChips() {
      return this.$store.state.selectedFilter === 0;
    },
    showBrandChips() {
      return this.$store.state.selectedFilter === 1;
    },
  },
  methods: {
    scrollToElement(options) {
      const el = this.$el.getElementsByClassName('mainContainer')[0];
      if (el) {
        el.scrollIntoView(options);
      }
    },
    onPageTop(e) {
      this.feed.currentPage = e;
      this.getData(0);
      this.$refs.bottomPagination.passivePageSelected(e);
    },
    onPageBottom(e) {
      this.feed.currentPage = e;
      this.getData(0);
      this.$refs.topPagination.passivePageSelected(e);
      this.scrollToElement({behavior: 'smooth'});
    },
    resetFeed() {
      this.feed = {
        totalPages: 0,
        totalItems: 0,
        items: [],
        curentPage: 0,
      };
    },
    refresh(data) {
      this.feed = {...data};
      console.log("feed", this.feed);
    },
    getData(e) {
      let currentFilter = this.$store.state.selectedFilter;

      const url = this.$root._route.fullPath;
      if (url.includes('reps?regionId=')) {
        const queryString = url.split('?')[1];
        const params = new URLSearchParams(queryString);
        const regionIds = params.getAll('regionId');
        this.$store.state.repFilter.regionIds = regionIds;
      }

      searchPageService.search(currentFilter, this.feed.currentPage).then(
          (response) => {
            this.refresh(response.data);

            // vue does not have a callback on event 'images were rendered', so this is the kludge for this case -
            // we give a time to render a page, and than crop the white borders for each logo on the page
            setTimeout(() => {
              removeBordersFromAllLogos();
            }, 500);

            this.chipsKey = Date.now();

          },
          (error) => console.log(error)
      );
    },
    runSearch() {
      // we need this method if we need to override a user-specific filters, i.e. to use virtual filter based on what user click on profile data
      let currentFilter = this.$store.state.selectedFilter;
      searchPageService.search(currentFilter, this.feed.currentPage).then(
          (response) => {
            this.refresh(response.data);
            this.searchRefresh += 1;
          },
          (error) => console.log(error)
      );
    },
    searchByRep(repId) {
      searchPageService.searchByRepId(repId).then(
          (response) => {
            this.refresh(response.data);
            this.searchRefresh += 1;
          },
          (error) => console.log(error)
      );
    },
    searchByEquipment(value) {
      searchPageService.searchByEquipmentName(value).then(
          (response) => {
            this.refresh(response.data);
            this.searchRefresh += 1;
            this.scrollToElement({behavior: 'smooth'});
          }
      )
    },
    exportReport(e) {
      let currentFilter = this.$store.state.selectedFilter;

      searchPageService.getCsvReport(currentFilter, this.feed.currentPage).then(
          (response) => {
            const url = window.URL.createObjectURL(new Blob([response.data]));
            const link = document.createElement('a');
            link.href = url;
            const fileName = `mafsi-locator-${new Date().toLocaleDateString()}-${new Date().toLocaleTimeString()}`;
            link.setAttribute('download', `${fileName}.csv`);
            document.body.appendChild(link);
            link.click();
          },
          (error) => console.log(error)
      );
    },
    resetForm(e) {
      console.log("reset form", e);

      if (e === 0) {
        this.$store.commit("resetRepForm");
      } else if (e === 1) {
        this.$store.commit("resetBrandForm");
      }

      this.searchRefresh += 1;
      this.getData(e);
    },
    changeTab(e) {
      console.log("change tab", e);
      if (1 === e) {
        this.$store.commit("resetRepForm");
      } else if (0 === e) {
        this.$store.commit("resetBrandForm");
      }
      this.resetFeed();
      this.resetForm(e);
    },
    onEnter() {
      this.getData();
    }
  },
  mounted() {
    searchPageService.getBrands();
    searchPageService.getLocationData();

    const {path, query} = this.$route;
    const isBrandPath = path === MF_LOOKING;
    const isRepPath = path === REPS_LOOKING;

    if (query && (isBrandPath || isRepPath)) {
      const regionGuids = Array.isArray(query.region) ? query.region.map(x => codeToGuidMapper[x]?.toLowerCase()) : [codeToGuidMapper[query.region]?.toLowerCase()];

      if (isBrandPath) {
        this.$store.commit("changeToBrandsTabFilter");
        this.$store.state.brandFilter.regionsNeededCoverageIds = regionGuids;
        this.$store.state.brandFilter.lookingForNewBrandsPartnersAllIds = ["00000000-0000-0000-0000-000000010310"];
      } else if (isRepPath) {
        this.$store.state.repFilter.regionIds = regionGuids;
        this.$store.state.repFilter.lookingForNewRepsPartnersAllIds = ["00000000-0000-0000-0000-000000010311"];
      }
    }

    this.getData(0);
  },

  components: {
    Search,
    Paginator,
    SelectedChips,
  },
};
</script>

<style scoped>
.search-page {
  display: flex;
  justify-content: flex-start;
}

.search-page > .filters {
  width: 550px;
}

.search-page > .content {
  width: 100%;
}

.records-counter {
  color: #aaa;
  text-align: left;
  padding-left: 4em;
  display: block;
  margin: 0;
}
</style>
