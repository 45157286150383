<template>
  <div class="content">
    <div v-if="profile" class="profile">

      <div class="grid">
          <div class="grid-box">
            <span style="dispay: box">
               <h2>{{ profile.name }}</h2>

              <span v-if="profile.surveyParticipation.indexOf('Barometer') > -1">
                <ImageComponent :src="require(`@/assets/social/mbb-icon.png`)" />
              </span>
              <span v-if="profile.surveyParticipation.indexOf('Benchmarking') > -1">
                <ImageComponent :src="require(`@/assets/social/mbs-icon.png`)" />
              </span>
              <span v-if="profile.surveyParticipation.indexOf('Market Forecast') > -1">
                <ImageComponent :src="require(`@/assets/social/mmf-icon.png`)" />
              </span>
              <span v-if="profile.specPathSubscriber.indexOf('Yes') > -1">
                <ImageComponent :src="require(`@/assets/social/specpath-icon.png`)" />
              </span>
              <span v-if="profile.memberStatus == 'current'">
                <ImageComponent :src="require(`@/assets/social/weAreMafsi-150.png`)" />
              </span>
              <span v-if="profile.mtcLevel.indexOf('Master') > -1">
                <ImageComponent
                  :src="require(`@/assets/social/mtc-master-logo.png`)"
                  :height="60"
                  :width="60"
                />
              </span>
              <span v-if="profile.mtcLevel.indexOf('Expert') > -1">
                <ImageComponent :src="require(`@/assets/social/mtc-expert-logo.png`)" />
              </span>
              <span v-if="profile.mtcLevel.indexOf('Advanced') > -1">
                <ImageComponent
                  :src="require(`@/assets/social/mtc-advanced-logo.png`)"
                />
              </span>
              <span v-if="profile.mtcLevel.indexOf('Core') > -1">
                <ImageComponent
                  :src="require(`@/assets/social/mtc-core-logo.png`)"
                />
              </span>
            </span>
          </div>

          <div class="grid-box">

            <span v-if="profile.logoImage != ''">
              <p align=center>
               <img v-bind:id="createElementId('logo', profile.uniqueId)"
               style="padding-left: 5em"
               width="200px" v-bind:src="profile.logoImage" />
              </p>
            </span>

          </div>

      </div>

      <Address :address="profile.billingAddress"></Address>

      <p>
        <DataLine caption="Company Phone: " :value="profile.phone" format="phone"></DataLine>
        <DataLine caption="800 Phone: " :value="profile.phone800" format="phone"></DataLine>
        <DataLine caption="Orders Dept. Phone: " :value="profile.phoneOrdersDept" format="phone" cr="false" ></DataLine>
      </p>

      <p>
        <DataLine caption="Company Email: " :value="profile.email" format="email"></DataLine>
        <DataLine caption="Orders Dept. Email: " :value="profile.emailOrdersDept" format="email"></DataLine>
      </p>

      <p>
        <DataLine caption="Company Fax: " :value="profile.fax" format="phone"></DataLine>
        <DataLine caption="Orders Dept. Fax: " :value="profile.faxOrdersDept"  format="phone" cr="false" ></DataLine>
      </p>

      <p>
         <DataLine caption="Website: " :value="profile.website" format="urlNewTab" ></DataLine>
      </p>

      <span v-if="profile.facebookUrl != '' || profile.linkedInUrl != '' || profile.instagramHandle != '' || profile.twitterHandle != '' || profile.youTubeChannel != '' ">
        <h3>Social Media:</h3>

        <span v-if="profile.facebookUrl">
          <a v-bind:href="profile.facebookUrl">
            <img src="@/assets/social/social-fb.png" />
          </a>
        </span>

        <span v-if="profile.linkedInUrl">
          <a v-bind:href="profile.linkedInUrl">
            <img src="@/assets/social/social-link.png" />
          </a>
        </span>

        <span v-if="profile.instagramHandle">
          <a v-bind:href="'http://www.instagram.com/' + profile.instagramHandle">
            <img src="@/assets/social/social-insta.png" />
          </a>
        </span>

        <span v-if="profile.twitterHandle">
          <a v-bind:href="'https://twitter.com/' + profile.twitterHandle">
            <img src="@/assets/social/social-tweet.png" />
          </a>
        </span>

        <span v-if="profile.youTubeChannel">
          <a v-bind:href="profile.youTubeChannel">
            <img src="@/assets/social/social-youtube.png" />
          </a>
        </span>
      </span>


      <p>
        <DataLine caption="Year Founded: " :value="profile.yearFounded" ></DataLine>
        <DataLine caption="Member Since: " :value="profile.memberSince" ></DataLine>
      </p>

      <p>
        <b>Region:</b> <Button @click="searchByRegion()" label="Search" class="virtual-button-link">{{ profile.region }}</Button><br/>
        <DataLine caption="Territory: " :value="profile.territory" format="semicolon" ></DataLine>
        <DataLine caption="This Rep Firm Also is also located in the following Regions: " :value="profile.additionalRegions" format="semicolon" ></DataLine>
      </p>

      <h3>Test Kitchen/Showroom/Chefs:</h3>
      <p>
        <DataLine caption="Test Kitchen: " :value="profile.testKitchen" ></DataLine>
        <DataLine caption="Mobile Test Kitchen: " :value="profile.mobileTestKitchen" ></DataLine>
        <DataLine caption="Number of Test Kitchens: " :value="profile.numberOfTestKitchens" ></DataLine>
        <DataLine caption="Test Kitchen Description: " :value="profile.testKitchenDescription" ></DataLine>
        <DataLine caption="Chef on Staff: " :value="profile.chefOnStaff" ></DataLine>
        <DataLine caption="Number of Chefs on Staff: " :value="profile.numberOfChefsOnStaff"></DataLine>
        <DataLine caption="Showroom: " :value="profile.showroom" ></DataLine>
        <DataLine caption="Number of Showrooms: " :value="profile.numberOfShowrooms" ></DataLine>
        <DataLine caption="Showroom Description: " :value="profile.showroomDescription" ></DataLine>
      </p>

      <p>
        <DataLine caption="Market Segment Expertise: " :value="profile.marketSegments" format="semicolon" ></DataLine>
      </p>

      <h3>Rep Firm Team:</h3>
      <p>
        <span class="fieldName">Total Personnel: </span>
        <span class="fieldData">{{ profile.totalPersonnel }}</span> <br />
        <span class="fieldName">Principal(s): </span>
        <span class="fieldData">{{
          profile.principals | formatSemicolon
        }}</span>
        <br />
        <span class="fieldName">Outside Sales: </span>
        <span class="fieldData">{{
          profile.outsideSales | formatSemicolon
        }}</span>
        <br />
        <span class="fieldName">Culinary & Sales: </span>
        <span class="fieldData">{{
          profile.culinaryAndSales | formatSemicolon
        }}</span>
        <br />
        <span class="fieldName">Culinary Only: </span>
        <span class="fieldData">{{
          profile.culinaryOnly | formatSemicolon
        }}</span>
        <br />
        <span class="fieldName">Inside Sales: </span>
        <span class="fieldData">{{
          profile.insideSales | formatSemicolon
        }}</span>
        <br />
        <span class="fieldName">Inside Support: </span>
        <span class="fieldData">{{
          profile.insideSupport | formatSemicolon
        }}</span>
      </p>

      <h3>Brands Represented:</h3>
      <p>
        <DataLine caption="Equipment: " @onValueClick="onEquipmentValueClick($event)" :value="profile.brandsRepresented.equipment" format="semicolon" ></DataLine>
        <DataLine caption="Supplies/Smallwares: " :value="profile.brandsRepresented.suppliesSmallwares" format="semicolon" ></DataLine>
        <DataLine caption="Tabletop: " :value="profile.brandsRepresented.tabletop" format="semicolon" ></DataLine>
        <DataLine caption="Furniture: " :value="profile.brandsRepresented.furniture" format="semicolon" ></DataLine>
        <DataLine caption="Janitorial/Sanitation/Disposables: " :value="profile.brandsRepresented.janitorialSanitationDisposables" format="semicolon" ></DataLine>
        <DataLine caption="Technology: " :value="profile.brandsRepresented.technology" format="semicolon" ></DataLine>
      </p>

      <span v-if="profile.linkToLineListing">
          <a v-bind:href="profile.linkToLineListing">
            <img src="@/assets/profiles/line-listing.png" height="70" />
          </a>
      </span>

      <h3>Product Categories Currently Represented:</h3>
      <p>
        <DataLine caption="Overall: " @onValueClick="onEquipmentValueClick($event)" :value="profile.productCategories.overall" format="semicolon" ></DataLine>
        <DataLine caption="Equipment: " @onValueClick="onEquipmentValueClick($event)" :value="profile.productCategories.equipment" format="semicolon" ></DataLine>
        <DataLine caption="Supplies/Smallwares: " :value="profile.productCategories.suppliesSmallwares" format="semicolon" ></DataLine>
        <DataLine caption="Tabletop: " :value="profile.productCategories.tabletop" format="semicolon" ></DataLine>
        <DataLine caption="Furniture: " :value="profile.productCategories.furniture" format="semicolon" ></DataLine>
        <DataLine caption="Janitorial/Sanitation/Disposables: " :value="profile.productCategories.janitorialSanitationDisposables" format="semicolon" ></DataLine>
        <DataLine caption="Technology: " :value="profile.productCategories.technology" format="semicolon" ></DataLine>
      </p>

      <span v-if="isLookingForNewPartners(profile)">
        <img src="@/assets/profiles/looking-new-partners-small.png" height="60px" />
        <h3>
          Looking for New Partners in these Categories for Region {{ profile.region }}:
        </h3>
        <p>
          <DataLine caption="Equipment: " @onValueClick="onEquipmentValueClick($event)" :value="profile.lookingForPartnersCategories.equipment" format="semicolon" ></DataLine>
          <DataLine caption="Supplies/Smallwares: " :value="profile.lookingForPartnersCategories.suppliesSmallwares" format="semicolon" ></DataLine>
          <DataLine caption="Tabletop: " :value="profile.lookingForPartnersCategories.tabletop" format="semicolon" ></DataLine>
          <DataLine caption="Furniture: " :value="profile.lookingForPartnersCategories.furniture" format="semicolon" ></DataLine>
          <DataLine caption="Janitorial/Sanitation/Disposables: " :value="profile.lookingForPartnersCategories.janitorialSanitationDisposables" format="semicolon" ></DataLine>
          <DataLine caption="Technology: " :value="profile.lookingForPartnersCategories.technology" format="semicolon" ></DataLine>
        </p>

        <p>
          <DataLine caption="Looking for New Partners – Contact Person: " :value="profile.lookingPartnersPerson" ></DataLine>
          <DataLine caption="Looking for New Partners – Contact Email: " :value="profile.lookingPartnersEmail" format="email"></DataLine>
          <DataLine caption="Looking for New Partners – Contact Phone (Optional): " :value="profile.lookingPartnersPhone" format="phone"></DataLine>
        </p>
      </span>

      <p>
        <DataLine caption="Rep Company Description: " :value="profile.repCompanyDescription" ></DataLine>
        <DataLine caption="Looking for New Partners in Region: " :value="profile.region" ></DataLine>
      </p>

      <span v-if="profile.branches.length > 0">
        <h3>Branch Offices(s):</h3>
      </span>
      <div v-for="branch in profile.branches" :key="branch.name">
        <h3>{{ branch.name }}</h3>

        <span class="fieldData">
          <Address :address="branch.branchAddress"></Address>
        </span>

        <DataLine caption="Branch Phone: " :value="branch.phone" format="phone"></DataLine>
        <DataLine caption="Branch Email: " :value="branch.email" format="email"></DataLine>

        <span v-if="isShowroomInfoExists(branch)">
          <h4>Branch Test Kitchen/Showroom:</h4>
          <p>
            <DataLine caption="Test Kitchen: " :value="branch.testKitchen" ></DataLine>
            <DataLine caption="Mobile Test Kitchen: " :value="branch.mobileTestKitchen" ></DataLine>
            <DataLine caption="Number of Test Kitchens: " :value="branch.numberOfTestKitchens" ></DataLine>
            <DataLine caption="Test Kitchen Description: " :value="branch.testKitchenDescription" ></DataLine>
            <DataLine caption="Chef on Staff: " :value="branch.chefOnStaff" ></DataLine>
            <DataLine caption="Number of Chefs on Staff: " :value="branch.numberOfChefsOnStaff" ></DataLine>
            <DataLine caption="Showroom: " :value="branch.showroom" ></DataLine>
            <DataLine caption="Number of Showrooms: " :value="branch.numberOfShowrooms" ></DataLine>
            <DataLine caption="Showroom Description: " :value="branch.showroomDescription" ></DataLine>
          </p>
        </span>

        <DataLine caption="Branch Office Team: " :value="branch.branchOfficeTeam" ></DataLine>
      </div>
    </div>
  </div>
</template>

<script>
import ImageComponent from "./ImageComponent";
import Address from './LayoutParts/Address.vue';
import DataLine from './LayoutParts/DataLine.vue';
import SearchPageService from "../services/searchService";

export default {
  name: "CompanyProfile",

  props: {
    profile: { type: Object, required: true },
  },

  data() {
    return {};
  },

  created() {},
  components: {
    ImageComponent,
    Address,
    DataLine
  },

  methods : {
    searchByRegion() {
      this.$store.state.repFilter.regionIds = this.profile.regionsSet;
      this.$parent.$parent.runSearch();
    },
    isLookingForNewPartners(profile) {
      if (typeof profile == 'undefined') {
        return false;
      }
      if (typeof profile.lookingForPartnersCategories == 'undefined') {
        return false;
      }

      return ((typeof profile.lookingForPartnersCategories.equipment !== 'undefined') && profile.lookingForPartnersCategories.equipment) ||
      ((typeof profile.lookingForPartnersCategories.suppliesSmallwares !== 'undefined') && profile.lookingForPartnersCategories.suppliesSmallwares) ||
      ((typeof profile.lookingForPartnersCategories.tabletop !== 'undefined') && profile.lookingForPartnersCategories.tabletop) ||
      ((typeof profile.lookingForPartnersCategories.furniture !== 'undefined') && profile.lookingForPartnersCategories.furniture) ||
      ((typeof profile.lookingForPartnersCategories.janitorialSanitationDisposables !== 'undefined') && profile.lookingForPartnersCategories.janitorialSanitationDisposables) ||
      ((typeof profile.lookingForPartnersCategories.technology !== 'undefined') && profile.lookingForPartnersCategories.technology);
    },

    isShowroomInfoExists(profile){
      if (typeof profile == 'undefined') {
        return false;
      }
      if (!profile) {
        return false;
      }

      return (profile.testKitchen != '' || profile.mobileTestKitchen != '' || profile.numberOfTestKitchens != '' || profile.testKitchenDescription != '' || profile.chefOnStaff != '' || profile.numberOfChefsOnStaff != '' ||
      profile.showroom != '' || profile.numberOfShowrooms != '' || profile.showroomDescription != '');
    },

    createElementId(prefix, profileId) {
      return prefix + "-" + profileId;
    }

  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
  }

  .grid-box {
    display: flex;
  }
</style>
