<template>
    <span v-if="isAddressValid(address)">
       <p>
         {{ address.address1 }}<br />
         <span v-if="address.address2 != ''">
           {{ address.address2 }}<br />
         </span>

        <span v-if="isCityAddressValid(address)">
          <span v-if="address.city != ''">
            {{ address.city }}, 
          </span>

          <span v-if="address.stateProvince != ''">
            {{ address.stateProvince }}, 
          </span>

          <span v-if="address.zipCode != ''">
            {{ address.zipCode }}, 
          </span>

          <span v-if="address.country != ''">
            {{ address.country }}
          </span>

         </span>
       </p>      
    </span>
</template>

<script>

export default {
    name: "Address",
    data() {
        return {

        }
    },
    methods: {
      isAddressValid: function(address)  {
        return address && (address.address1 || address.address2 || address.city || address.stateProvince || address.zipCode || address.country);
      },

      isCityAddressValid: function(address)  {
        return address && (address.city || address.stateProvince || address.zipCode);
      },

      getLocationString: function(address)  {
        if (!address) {
          return "";
        }
        return address.city + ', ' + address.stateProvince + ', ' + address.zipCode + ', ' + address.country;
      }
    },
    props: {
    address: { type: Object, required: true }
    },
}
</script>

<style scoped>

</style>
