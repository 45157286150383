import axios from 'axios';

class HttpService {
    _baseUrl;
    constructor() {
        // Use it for development
        // this._baseUrl = 'http://localhost:59007';

        // Use it for deployment on production
        this._baseUrl = 'https://mafsiapi.org';
    }
    doQuery(method) {
        window.EventBus.dispatchEvent('loading-started');

        return new Promise((resolve, reject) => {
            method()
                .then(resolve, reject);
        })
            .finally(() => {
                window.EventBus.dispatchEvent('loading-ended');
            });
    }

    get(url, params) {
        console.log(url);
        return this.doQuery(() => axios.get(`${this._baseUrl}${url}`, { params: params }));
    }

    getBase(baseUrl, url, params) {
        return this.doQuery(() => axios.get(`${baseUrl}${url}`, { params: params }));
    }
    postBase(baseUrl, url, params) {
        return this.doQuery(() => axios.post(`${baseUrl}${url}`, params));
    }

    post(url, params) {
        return this.doQuery(() => axios.post(`${this._baseUrl}${url}`, params));
    }

}

export let httpService = new HttpService();