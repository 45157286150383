<template>
  <div>
    <div v-if="caption">{{ caption }} ({{ elementsCount }})</div>
    <div class="match-all" v-if="matchAllVisible && checkedCount >= 2">
      <Checkbox
        :binary="true"
        v-model="andChecked"
        @change="matchAllChanged()"
      />
      <label>is match to all &nbsp;</label>
    </div>
    <div class="search" v-if="searchable">
      <input type="text" placeholder="Search" v-model="search" />
    </div>
    <div class="alphabet" v-if="alpIndex">
      <div>
        <a
          @click="
            () => {
              this.showAlphabet = !this.showAlphabet;
            }
          "
          >Alphabetical index</a
        >
      </div>
      <div v-if="showAlphabet">
        <span v-for="letter in letters" :key="letter">
          <a href="javascript:;" @click="filterByLetter($event)">{{
            letter
          }}</a>
        </span>
      </div>
    </div>
    <div class="check-list" :style="{ height: height + 'px' }">
      <div v-for="(value, index) in list" :key="index">
        <template v-if="alpIndex">
          <!-- <div v-if="index === 0">{{ value.firstLetter }}</div>
          <div
            v-else-if="list[index - 1].firstLetter != list[index].firstLetter"
          >
            {{ value.name.charAt(0) }}
          </div> -->
          <div v-if="value.showFirstLetter">{{ value.firstLetter }}</div>
        </template>
        <div class="item">
          <!-- <Checkbox
            style="margin-right: 5px"
            :id="value.id"
            :binary="true"
            v-model="value.checked"
            @change="onChange($event, value)"
          /> -->
          <!-- <input
            type="checkbox"
            :id="value.id"
           v-model="value.checked"
            @change="onChange($event, value)"
          /> -->
          <input
            class="custom-checkbox pi pi-check"
            type="checkbox"
            :id="value.id"
            v-model="value.checked"
            @change="onChange($event, value)"
          />
          <label :for="value.id">
            {{ value.name }}
          </label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Checkbox from "primevue/checkbox";
export default {
  data() {
    return {
      list: [],
      search: "",
      andChecked: false,
      showAlphabet: false,
      listHeight: 200,
      letters: [
        "1",
        "2",
        "3",
        "4",
        "5",
        "6",
        "7",
        "8",
        "9",
        "A",
        "B",
        "C",
        "D",
        "E",
        "F",
        "G",
        "H",
        "I",
        "J",
        "K",
        "L",
        "M",
        "N",
        "O",
        "P",
        "Q",
        "R",
        "S",
        "T",
        "U",
        "V",
        "W",
        "X",
        "Y",
        "Z",
      ],
    };
  },
  props: {
    values: { type: Array, required: true },
    value: { type: Array, required: true },
    alpIndex: { type: Boolean, required: false },
    caption: { type: String, required: false },
    height: { type: Number, required: false },
    searchable: { type: Boolean, required: false },
    matchAll: { type: Boolean, required: false },
    matchAllVisible: { type: Boolean, required: false, default: false },
  },
  computed: {
    selectedIds() {
      return this.values.filter((x) => x.checked).map((x) => x.id);
    },
    elementsCount() {
      return this.values.length;
    },
    checkedCount() {
      console.log(
        "checked count",
        this.values.filter((x) => x.checked).length,
        this.andChecked
      );
      // return this.values.filter((x) => x.checked).length;
      return this.value.length;
    },
  },
  watch: {
    search: function (newValue) {
      let oneLetter = (x) =>
        x.name.charAt(0).toLowerCase() === newValue.charAt(0).toLowerCase();
      let contain = (x) =>
        x.name.toLowerCase().includes(newValue.toLowerCase());

      let filtered = this.values.filter(
        newValue.length === 1 ? oneLetter : contain
      );

      this.list = [...filtered];
    },
  },
  methods: {
    getFirstChar(str) {
      return str.charAt(0).toUpperCase();
    },
    matchAllChanged() {
      this.$emit("matchAllChanged", this.andChecked);
    },
    onChange(e, value) {
      console.log(e, value);
      let selected = this.values.filter((x) => x.checked).map((x) => x.id);

      // attach and checbox
      this.$emit("input", [...selected]);
    },
    filterByLetter(e) {
      this.search = e.target.innerText;
    },
  },
  created() {
    // set matchAll checkbox
    console.log("matchAll", this.matchAll);
    this.andChecked = this.matchAll;
  },
  mounted() {
    console.log("multiple mounted", this.value);
    // add first letter to value object
    this.values.forEach((x) => {
      // x.firstLetter = x.name.charAt(0).toUpperCase();

      //sync selected values
      x.checked = this.value.includes(x.id) ? true : false;
    });
    this.list = [...this.values];

    // set height
    this.listHeight = this.height ? this.height : this.listHeight;
  },
  components: {
    Checkbox,
  },
};
</script>

<style scoped>
.match-all {
  display: flex;
  justify-content: flex-end;
  color: gray;
  align-items: flex-end;
}
.match-all label {
  margin-left: 5px;
  font-size: 80%;
}
.check-list {
  display: flex;
  flex-direction: column;
  overflow: auto;
}

.check-list .item {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.alphabet a {
  margin-left: 15px;
  display: inline-block;
  cursor: pointer;
}

/* custom checkbox */
.custom-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
}
.custom-checkbox + label {
  display: inline-flex;
  align-items: center;
  user-select: none;
}
.custom-checkbox + label::before {
  content: "";
  display: inline-block;
  width: 1em;
  height: 1em;
  flex-shrink: 0;
  flex-grow: 0;
  border: 1px solid #adb5bd;
  border-radius: 0.25em;
  margin-right: 0.5em;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 50% 50%;
}

.custom-checkbox:checked + label::before {
  border-color: #0b76ef;
  background-color: #0b76ef;
  background-image: url("../../assets/checkmark.png");
}
</style>