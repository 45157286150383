<template>
  <div class="search">
    <div class="actions top-buttons">
      <Button @click="search()" label="Search" class="searchButton" />
      <Button
        @click="reset()"
        label="Reset"
        class="resetButton"
        style="margin-left: 5px"
      />
      <Button @click="exportCsvReport()" icon="pi pi-download" class="p-button-rounded p-button-text" style="margin-left:5px;"/>
    </div>
    <TabView :key="NaN" :activeIndex.sync="active" @tab-change="tabChange($event)">
      <TabPanel>
        <template #header>
          <i class="pi pi-users"></i>
          <span>&nbsp;Reps</span>
        </template>
        <RepFilter @onEnter="onEnter()"/>
      </TabPanel>
      <TabPanel>
        <template #header>
          <i class="pi pi-briefcase"></i>
          <span>&nbsp;Brands</span>
        </template>
        <BrandFilter @onEnter="onEnter()"/>
      </TabPanel>
    </TabView>
    <div class="actions">
      <Button @click="search()" label="Search" class="searchButton" />
      <Button
        @click="reset()"
        label="Reset"
        class="resetButton"
        style="margin-left: 5px"
      />
    </div>
  </div>
</template>

<script>
import TabView from "primevue/tabview";
import TabPanel from "primevue/tabpanel";
import RepFilter from "./RepFilter.vue";
import BrandFilter from "./BrandFilter.vue";
import Button from "primevue/button";
import {MF_LOOKING, REPS_LOOKING} from "@/common/global";

export default {
  data() {
    return {
     // active: 0,
    };
  },
  computed: {
    active: {
      get() {
        return this.$store.state.selectedFilter;
      },
      set(val) {
        this.$store.state.selectedFilter = val;
      },
    },
  },
  methods: {
    onEnter(){
      this.$emit('onEnter');
    },
    test(){
      console.log('test active', this.active);
      return this.active === 0;
    },
     test2(){
       console.log('test active2', this.active);
      return this.active === 1;
    },
    search() {
      console.log("selectedFilter33", this.$store.state.selectedFilter);
      //this.$store.state.selectedFilter = this.active;
      this.$emit("searchEvent", this.active);
    },
    exportCsvReport() {
      this.$emit('exportCsvReport');
    },
    reset() {
      localStorage.removeItem('route-path');
      const url = this.$root._route.fullPath;

      if (url.includes(MF_LOOKING) || url.includes(REPS_LOOKING)) {
        this.$root._router.replace({path: '/'});
      }
      this.$emit("resetForm", this.active);
    },
    tabChange(e) {
      this.reset();
      this.$emit("change", this.active);
    },
  },
  mounted() {
    console.log("selectedFilter", this.$store.state.selectedFilter);
  },
  components: {
    TabView,
    TabPanel,
    RepFilter,
    BrandFilter,
    Button,
  },
};
</script>

<style scoped>
.search {
  padding: 5px;
}

.actions {
  padding: 5px;
}

.top-buttons {
  position: absolute;
  left: 215px;
}

.searchButton {
  background-color: #1DB954;
  color: white;
  font-weight: 700;
  font-size: 16px;
  border-color: #1DB954;
}

.resetButton {
  background-color: #FFCDD2;    
  color: #EE2B32;
  font-weight: 700;
  font-size: 16px;
  border-color: #EE2B32;
}
</style>
