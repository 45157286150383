<template>
	<div class="content">
        <div v-if="singleProfile" class="profile">

            <div v-if="singleProfile.profileType == 'Rep'">
                <CompanyProfile />  
            </div>
            <div v-if="singleProfile.profileType == 'Brand'">
                <BrandProfile />  
            </div>
            <div v-if="singleProfile.profileType == 'Manufacturer'">
                <ManufacturerProfile />  
            </div>
        </div>
    </div>
</template>

<script>

export default {
	name: 'SingleProfile',
    
    props: {
        id: String
    },
    
	data() {
		return {	
			loading: true,		
			singleProfile: null
		};
	},
	
	created() {		

        console.log("id=" + JSON.stringify(this.$props.id, null, '\t') );
        /*
		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },			
		}
		
		axios.post("http://localhost:59007/test/GetLocatorProfileCached?noviUniqueId=B9115B84-FDAA-4FFD-9A25-FE5F1C5646B5")
		.then(response => (this.singleProfile = response.data), this.loading = false);
        */
    },

    mounted(){
        console.log("id=" + JSON.stringify(this.$props.id, null, '\t') );
    }
}
</script>


<style scoped>
	.profile {
		text-align: left;
		padding: 2em 5em 2em 5em;
		font-size: 80%;
	}

	.fieldName {
		font-weight: 600;
	}

	.fieldData {
		color: darkgrey;
	}

</style>
