<template>
  <div class="content">
    <div v-if="profile" class="profile">
       <div class="grid">
          <div class="grid-box">
            <span style="display: box">
              <h2>{{ profile.name }}</h2>

              <span
                v-if="profile.manufacturer.surveyParticipation &&
                  profile.manufacturer.surveyParticipation.indexOf('Barometer') > -1
                "
              >
                <ImageComponent
                  :src="require(`@/assets/social/mbb-icon.png`)"
                  :height="60"
                  :width="60"
                />
              </span>
              <span
                v-if="profile.manufacturer.surveyParticipation &&
                  profile.manufacturer.surveyParticipation.indexOf('Benchmarking') > -1
                "
              >
                <ImageComponent
                  :src="require(`@/assets/social/mbs-icon.png`)"
                  :height="60"
                  :width="60"
                />
              </span>
              <span
                v-if="profile.manufacturer.surveyParticipation &&
                  profile.manufacturer.surveyParticipation.indexOf('Market Forecast') >
                  -1
                "
              >
                <ImageComponent :src="require(`@/assets/social/mmf-icon.png`)" />
              </span>
              <span v-if="profile.manufacturer.specPathSubscriber && profile.manufacturer.specPathSubscriber.indexOf('Yes') > -1">
                <img src="@/assets/social/specpath-icon.png" width="60" height="60" />
              </span>
              <span v-if="profile.manufacturer.mtcLevel && profile.manufacturer.mtcLevel.indexOf('Master') > -1">
                <img src="@/assets/social/mtc-master-logo.png" width="60" height="60" />
              </span>
              <span v-if="profile.manufacturer.mtcLevel && profile.manufacturer.mtcLevel.indexOf('Expert') > -1">
                <img src="@/assets/social/mtc-expert-logo.png" width="60" height="60" />
              </span>
              <span v-if="profile.manufacturer.mtcLevel && profile.manufacturer.mtcLevel.indexOf('Advanced') > -1">
                <ImageComponent
                  :src="require(`@/assets/social/mtc-advanced-logo.png`)"
                />
              </span>
              <span v-if="profile.manufacturer.mtcLevel && profile.manufacturer.mtcLevel.indexOf('Core') > -1">
                <!-- <img src="@/assets/social/mtc-core-logo.png" width="60" height="60" /> -->
            <ImageComponent
                  :src="require(`@/assets/social/mtc-core-logo.png`)"
                />
              </span>
              <span v-if="profile.memberStatus == 'current' && profile.manufacturer.memberStatus == 'current'">
                <ImageComponent :src="require(`@/assets/social/weAreMafsi-150.png`)" />
              </span>
            </span>
          </div>
          <div class="grid-box">
              <span v-if="profile.logoImage != ''">
                <img v-bind:id="createElementId('logo', profile.uniqueId)" width="150px" v-bind:src="profile.logoImage" />
              </span>
          </div>
       </div>

      <span v-if="profile.manufacturer && profile.manufacturer.billingAddress">
        <span class="fieldData">
          <Address :address="profile.manufacturer.billingAddress"></Address>
        </span>
      </span>

      <p>
        <DataLine caption="Company Phone: " :value="profile.manufacturer.phone" format="phone"></DataLine>
        <DataLine caption="800 Phone: " :value="profile.manufacturer.phone800" format="phone"></DataLine>
        <DataLine caption="Orders Dept. Phone: " :value="profile.manufacturer.phoneOrdersDept" format="phone" cr="false" ></DataLine>
      </p>

      <p>
        <DataLine caption="Company Email: " :value="profile.manufacturer.email" format="email"></DataLine>
        <DataLine caption="Orders Dept. Email: " :value="profile.manufacturer.emailOrdersDept" format="email"></DataLine>
      </p>

      <p>
        <DataLine caption="Company Fax: " :value="profile.manufacturer.fax" format="phone"></DataLine>
        <DataLine caption="Orders Dept. Fax: " :value="profile.manufacturer.faxOrdersDept"  format="phone" cr="false" ></DataLine>
      </p>

      <p>
        <DataLine caption="Website: " :value="profile.website" format="urlNewTab"></DataLine>
        <DataLine caption="Product Categories: " :value="profile.productCategoriesManual" format="semicolon"></DataLine>
      </p>

      <span v-if="profile.linkToProductListing">
        <a v-bind:href="profile.linkToProductListing">
          <img src="@/assets/profiles/product-listing.png" height="70" />
        </a>
      </span>

      <span v-if="profile.reps.length > 0">
        <h3>Current Rep Firms:</h3>
        <ul class="reps-list" style="margin: 4px" v-for="rep in profile.reps" :key="rep.companyUniqueId">
          <li>
            <a v-bind:href="getRegionsUrl(rep)"> {{ rep.region }} </a> - <span class="fieldData"><a @click="searchByRep(rep)" href="#">{{ rep.companyName }}</a></span>
          </li>
        </ul>
      </span>


      <span v-if="isLookingForNewPartners(profile)">
        <h3>Looking for New Partners in these Regions and Categories:</h3>

        <img src="@/assets/profiles/looking-new-partners-small.png" height="60px" />

        <p>
          <DataLine caption="Equipment: " :value="profile.lookingForNewPartners.equipment" format="semicolon" ></DataLine>
          <DataLine caption="Supplies/Smallwares: " :value="profile.lookingForNewPartners.suppliesSmallwares" format="semicolon" ></DataLine>
          <DataLine caption="Tabletop: " :value="profile.lookingForNewPartners.tabletop" format="semicolon" ></DataLine>
          <DataLine caption="Furniture: " :value="profile.lookingForNewPartners.furniture" format="semicolon" ></DataLine>
          <DataLine caption="Janitorial/Sanitation/Disposables: " :value="profile.lookingForNewPartners.janitorialSanitationDisposables" format="semicolon" ></DataLine>
          <DataLine caption="Technology: " :value="profile.lookingForNewPartners.technology" format="semicolon" ></DataLine>

          <DataLine caption="Regions Needing Coverage: " :value="profile.regionsNeedingCoverage" format="semicolon"></DataLine>
        </p>
        <p>
          <DataLine caption="Looking for New Partners – Contact Person: " :value="profile.lookingForNewPartnersContactPerson" ></DataLine>
          <DataLine caption="Looking for New Partners – Contact Email: " :value="profile.lookingForNewPartnersContactEmail" format="email"></DataLine>
          <DataLine caption="Looking for New Partners – Contact Phone (Optional): " :value="profile.lookingForNewPartnersContactPhone" format="phone"></DataLine>
        </p>
      </span>

      <p>
        <DataLine caption="Brand Description: " :value="profile.manufacturer.repCompanyDescription" ></DataLine>
      </p>

      <span class="mf-subheader">Manufacturer Information</span>


      <span v-if="profile.manufacturer.logoImage != ''">
        <p>
          <img v-bind:id="createElementId('logo', profile.uniqueId, profile.manufacturer.uniqueId)" width="150px" v-bind:src="profile.manufacturer.logoImage" />
        </p>
      </span>

      <h3>{{ profile.manufacturer.name }}</h3>
      <p>
        <DataLine caption="Do you provide Electronic Commission Statements? " :value="profile.manufacturer.electronicCommissionStatements" ></DataLine>
      </p>

      <p>
        <span v-if="profile.manufacturer.brandsRepresented">
            <span class="mf-subheader">All Manufacturer Brand(s): </span>
            <DataLine caption="Equipment: " @onValueClick="onEquipmentValueClick($event)" :value="profile.manufacturer.brandsRepresented.equipment" format="semicolon-url" ></DataLine>
            <DataLine caption="Supplies/Smallwares: " @onValueClick="onEquipmentValueClick($event)" :value="profile.manufacturer.brandsRepresented.suppliesSmallwares" format="semicolon-url" ></DataLine>
            <DataLine caption="Tabletop: " @onValueClick="onEquipmentValueClick($event)" :value="profile.manufacturer.brandsRepresented.tabletop" format="semicolon-url" ></DataLine>
            <DataLine caption="Furniture: " @onValueClick="onEquipmentValueClick($event)" :value="profile.manufacturer.brandsRepresented.furniture" format="semicolon-url" ></DataLine>
            <DataLine caption="Janitorial/Sanitation/Disposables: " @onValueClick="onEquipmentValueClick($event)" :value="profile.manufacturer.brandsRepresented.janitorialSanitationDisposables" format="semicolon-url" ></DataLine>
            <DataLine caption="Technology: " @onValueClick="onEquipmentValueClick($event)" :value="profile.manufacturer.brandsRepresented.technology" format="semicolon-url" ></DataLine>
            <DataLine caption="Non-Rep Brands: " @onValueClick="onEquipmentValueClick($event)"  :value="profile.manufacturer.brandsRepresented.nonRep" format="semicolon-url" ></DataLine>
        </span>
      </p>

      <span v-if="isSocialMediaExists(profile.manufacturer)">
          <h3>Social Media:</h3>

          <span v-if="profile.manufacturer.facebookUrl">
            <a v-bind:href="profile.manufacturer.facebookUrl">
              <img src="@/assets/social/social-fb.png" />
            </a>
          </span>

          <span v-if="profile.manufacturer.linkedInUrl">
            <a v-bind:href="profile.manufacturer.linkedInUrl">
              <img src="@/assets/social/social-link.png" />
            </a>
          </span>

          <span v-if="profile.manufacturer.instagramHandle">
            <a v-bind:href="'http://www.instagram.com/' + profile.manufacturer.instagramHandle">
              <img src="@/assets/social/social-insta.png" />
            </a>
          </span>

          <span v-if="profile.manufacturer.twitterHandle">
            <a v-bind:href="profile.manufacturer.twitterHandle">
              <img src="@/assets/social/social-tweet.png" />
            </a>
          </span>

          <span v-if="profile.manufacturer.youTubeChannel">
            <a v-bind:href="profile.manufacturer.youTubeChannel">
              <img src="@/assets/social/social-youtube.png" />
            </a>
          </span>
      </span>

      <p>
        <DataLine caption="Year Founded: " :value="profile.manufacturer.yearFounded" ></DataLine>
        <DataLine caption="Member Since: " :value="profile.manufacturer.memberSince" ></DataLine>
      </p>

      <span class="mf-subheader">Manufacturer Team: </span>
      <p>
        <span class="fieldName">Executive Team: </span>
        <span class="fieldData">{{ profile.manufacturer.executiveTeam }}</span>
        <br />
        <span class="fieldName">Sales/Marketing Manager(s): </span>
        <span class="fieldData">{{
          profile.manufacturer.salesMarketingManager
        }}</span>
        <br />
        <span class="fieldName">Customer Service Team: </span>
        <span class="fieldData">{{
          profile.manufacturer.customerServiceTeam
        }}</span>
        <br />
        <span class="fieldName"
          >Culinary/Coordinator/Admin/Tradeshow/Other: </span
        >
        <span class="fieldData">{{
          profile.manufacturer.culinaryCoordinatorAdminTradesOther
        }}</span>
        <br />
      </p>
    </div>
  </div>
</template>

<script>
import ImageComponent from "./ImageComponent";
import Address from './LayoutParts/Address.vue';
import DataLine from './LayoutParts/DataLine.vue';
export default {
  name: "BrandProfile",

  props: {
    profile: { type: Object, required: true },
  },

  data() {
    return {};
  },

  created() {},
  methods: {
    searchByRep(rep) {
      this.$emit("searchEvent", rep.companyUniqueId);
    },

    getRegionsUrl(rep) {
      if (!(rep.regionIds || rep.regionIds.length == 0)) {
        return '#';
      }
      return '/reps?regionId=' + rep.regionIds.join("&regionId=");
    },

    onEquipmentValueClick(value){
      this.$emit("onEquipmentValueClick", value);
    },

    isLookingForNewPartners(profile) {
      if (typeof profile == 'undefined') {
        return false;
      }
      if (typeof profile.lookingForNewPartners == 'undefined') {
        return false;
      }

      return ((typeof profile.lookingForNewPartners.equipment !== 'undefined') && profile.lookingForNewPartners.equipment) ||
      ((typeof profile.lookingForNewPartners.suppliesSmallwares !== 'undefined') && profile.lookingForNewPartners.suppliesSmallwares) ||
      ((typeof profile.lookingForNewPartners.tabletop !== 'undefined') && profile.lookingForNewPartners.tabletop) ||
      ((typeof profile.lookingForNewPartners.furniture !== 'undefined') && profile.lookingForNewPartners.furniture) ||
      ((typeof profile.lookingForNewPartners.janitorialSanitationDisposables !== 'undefined') && profile.lookingForNewPartners.janitorialSanitationDisposables) ||
      ((typeof profile.lookingForNewPartners.technology !== 'undefined') && profile.lookingForNewPartners.technology);
    },

    isSocialMediaExists(profile) {
      if (typeof profile == 'undefined') {
        return false;
      }
      if (!profile) {
        return false;
      }

      if (profile.facebookUrl || profile.linkedInUrl || profile.instagramHandle || profile.instagramHandle || profile.twitterHandle || profile.youTubeChannel ) {
        return true;
      }
      return false;
    },

    createElementId(prefix, id1, id2) {
      return prefix + "-" + id1 + "-" + id2;
    }

  },
  components: {
    ImageComponent,
    Address,
    DataLine
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.reps-list ul {
  margin: 0;
  padding: 0;
  margin-block-end: 0;
  margin-block-start: 0;
}

  .mf-subheader {
    color: lightgray;
    font-weight: bold;
  }

  .grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
  }

  .grid-box {
    display: flex;
  }
</style>