
import $ from 'jquery';

function createElementId(prefix, profileId) {
    return prefix + "-" + profileId;
}

export function removeBordersFromAllLogos() {
    const originalLogos = self.document.querySelectorAll(`[id^="logo-"]`);
    originalLogos.forEach(element => {
        removeBlanks(element.currentSrc, element.id.replace("logo-", ""));
    });
}


function removeBlanks(url, id) {

    var hostId = createElementId("logo", id);
    var hostElement = self.document.getElementById(hostId);
    if (!hostElement) {
        console.log("logo host element not found");
        return;
    }

    var img = new Image(),
    $canvas = $("<canvas>"),
    canvas = $canvas[0],
    context;

    var removeBlanks = function (imgWidth, imgHeight) {
        var imageData = context.getImageData(0, 0, imgWidth, imgHeight),
            data = imageData.data,
            getRBG = function(x, y) {
                var offset = imgWidth * y + x;
                return {
                    red:     data[offset * 4],
                    green:   data[offset * 4 + 1],
                    blue:    data[offset * 4 + 2],
                    opacity: data[offset * 4 + 3]
                };
            },
            isWhite = function (rgb) {
                // many images contain noise, as the white is not a pure #fff white
                return rgb.red > 200 && rgb.green > 200 && rgb.blue > 200;
            },
            scanY = function (fromTop) {
                var offset = fromTop ? 1 : -1;
                
                // loop through each row
                for(var y = fromTop ? 0 : imgHeight - 1; fromTop ? (y < imgHeight) : (y > -1); y += offset) {
                    
                    // loop through each column
                    for(var x = 0; x < imgWidth; x++) {
                        var rgb = getRBG(x, y);
                        if (!isWhite(rgb)) {
                            return y;                        
                        }      
                    }
                }
                return null; // all image is white
            },
            scanX = function (fromLeft) {
                var offset = fromLeft? 1 : -1;
                
                // loop through each column
                for(var x = fromLeft ? 0 : imgWidth - 1; fromLeft ? (x < imgWidth) : (x > -1); x += offset) {
                    
                    // loop through each row
                    for(var y = 0; y < imgHeight; y++) {
                        var rgb = getRBG(x, y);
                        if (!isWhite(rgb)) {
                            return x;                        
                        }      
                    }
                }
                return null; // all image is white
            };
        
        var cropTop = scanY(true),
            cropBottom = scanY(false),
            cropLeft = scanX(true),
            cropRight = scanX(false),
            cropWidth = cropRight - cropLeft,
            cropHeight = cropBottom - cropTop;
        
        var $croppedCanvas = $("<canvas>").attr({ width: cropWidth, height: cropHeight });
        
        // finally crop the guy
        $croppedCanvas[0].getContext("2d").drawImage(canvas,
            cropLeft, cropTop, cropWidth, cropHeight,
            0, 0, cropWidth, cropHeight);
        
        var dataURL = $croppedCanvas[0].toDataURL();
        hostElement.src = dataURL;
        
        // console.log(cropTop, cropBottom, cropLeft, cropRight);
    };
    
    img.crossOrigin = "anonymous";
    img.onload = function () {
        $canvas.attr({ width: this.width, height: this.height });
        context = canvas.getContext("2d");
        if (context) {
            context.drawImage(this, 0, 0);
            removeBlanks(this.width, this.height);
        } else {
            alert('Get a real browser!');
        }
    };
    
    // define here an image from your domain
    img.src = url;
}