<template>
  <div>
  </div>
</template>
<script>
import { ssoService } from "../services/ssoService";
export default {
  data() {
    return { token: "" };
  },
  mounted() {
    // --- test ----
    const routePathFromStorage = localStorage.getItem('route-path');
    const routePath = routePathFromStorage;
    localStorage.removeItem('route-path');

    console.log("CALLBACK---MOUNTED");
    console.log("ROUTE PATH", routePath);

    let token= this.$router.currentRoute.query.token;
    this.token = token;
    localStorage.setItem('token', token);

    if(routePath){
      console.log("INSIDE ROUTE PATH");

      ssoService.handleAuth().then(data => this.$router.push({ path: routePath.path, query: routePath.query }));
    } else {
      console.log(" DEFAULT ROUTE PATH");

      ssoService.handleAuth().then(data => this.$router.push({name: 'home'}));
    }
    localStorage.setItem('route-path', '');
    // ssoService.handleAuth().then(data => this.$router.push({name: 'home'}));
  },
};
</script>