export const codeToGuidMapper = {
    "01":	"D431F121-063D-4A1B-8674-627A9A1BAE7F",
    "02":	"D4A58AE1-7A92-4C66-99DF-838AF0E66895",
    "03":	"06631057-8924-4114-9DB9-AB0D73F00975",
    "04":	"1E82CF73-5772-4A2C-816A-576632E70D10",
    "05":	"7A226BE6-9F1C-4D0B-B8BE-BD524EB62CB6",
    "06":	"2100D9A1-8444-4E8C-859E-F0E7C729C986",
    "07":	"5FE33B4A-35F9-412B-BBBA-D4EBED129347",
    "11":	"970A29E7-EC35-4542-BBFE-6705BBF2074B",
    "12":	"01180E3B-5E35-44EF-AA60-92ABAA57EC66",
    "13":	"F5B070CB-143C-4483-8805-C56C2F8CF9DF",
    "14":	"D4802FAD-59A1-4EED-A88C-4B16481FA07C",
    "15":	"9184A91C-6F80-4B6F-BC3E-052D993B292A",
    "16":	"A75C3DEE-C3EA-4145-873C-55232D9E113D",
    "17":	"8E773813-52E6-4D26-A1FC-5A24A93D5616",
    "18":	"FB305415-6CBA-4B37-8A04-AC8E8C177354",
    "19":	"14299B7D-9B9B-4E63-B51C-AF5DF84329D7",
    "20":	"E03FF9D7-7574-4FB6-9E83-EEEBE948F630",
    "21":	"4ABB12C2-47DD-445D-B2FA-FCAFEE6EE7F0",
    "22":	"06053BFB-CD73-4B6C-ABE1-354A1D9737B0",
    "24":	"58F9EFAA-0D16-45E9-BC36-F1421FCF1D4A",
    "25":	"9097727A-1244-4896-BC53-57763489DEBB",
    "26a":	"AADB940F-19EA-416A-B64B-0AC06209B8F9",
    "26b":	"2F8D668C-7DBA-46AD-8637-E892E2DBA7F0",
    "26c":	"561288AA-F65A-457E-9476-304DAA57FD72",
    "26d":	"B2D3702A-FEDD-4432-A84C-2A041ECE9EED",
    "26e":	"67A152B4-3758-4CD2-AD2C-9B51E2293E11",
    "26f":	"694CEC50-E529-4A48-B586-34C4BB01BE7A",
    "30":	"D731DDFD-5A9E-3030-968A-1B44652DABFC",
    "40":	"D731DDFD-5A9E-4040-968A-1B44652DABFC",
    "50":	"D731DDFD-5A9E-5050-968A-1B44652DABFC",
    "60":	"D731DDFD-5A9E-6060-968A-1B44652DABFC",
    "70":	"D731DDFD-5A9E-7070-968A-1B44652DABFC",
    "80":	"D731DDFD-5A9E-8080-968A-1B44652DABFC",
    "Undefined": "1C3E6824-9A12-433C-B886-97E15BB8CC18"
};

