<template>
  <div class="image-card">
    <img
      :src="src"
      :width="currentWidth"
      :height="currentHeight"
      @mouseenter="mouseOver"
      @mouseout="mouseOut"
      @click="onClick()"
    />
    <Dialog header="Info" :visible.sync="display">
      <img src="@/assets/locator-key.png" />
    </Dialog>
  </div>
</template>
<script>
import Dialog from "primevue/dialog";
export default {
  data() {
    return {
      currentHeight: 0,
      currentWidth: 0,
      display: false,
    };
  },
  props: {
    src: { type: String, required: false },
    width: { type: Number, required: false },
    height: { type: Number, required: false },
  },
  computed: {
    source() {
      return this.src;
    },
  },
  methods: {
    mouseOver() {
      this.currentHeight += 1;
      this.currentWidth += 1;
    },
    mouseOut() {
      this.currentHeight -= 1;
      this.currentWidth -= 1;
    },
    onClick() {
      this.display = true;
    },
  },
  mounted() {
    this.currentHeight = this.height? this.height: 60;
    this.currentWidth = this.width? this.width: 60;
  },
  components: {
    Dialog,
  },
};
</script>
<style scoped>
.image-card {
  position: relative;
  display: inline-block;
  cursor: pointer;
  width: 65px;
  height: 65px;
}
.image-card > img {
  position: absolute;
}
</style>