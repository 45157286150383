<template>
  <div class="selected-items">
    <template  v-for="(ch, index) in list">
    <Chip
      :style="'margin: 5px;'"
     
      :key="index"
      :label="ch.name"
      v-if="show(ch.name)"
      removable
      @remove="onRemove($event, ch.id, ch.arrName)"
    />
    </template>

  </div>
</template>

<script>
import Chip from "primevue/chip";
import { chipsService } from "../../services/chipsService";

export default {
  data() {
    return {
      list: [],
      testKey: 0,
    };
  },
  props: {
    filter: { type: Object },
  },
  methods: {
     show(e){
      return e && e.length > 0? true: false;
    },
    onRemove(e, id, arrName) {
      if (this.$store.state.selectedFilter == 0)
        chipsService.onRepFilterRemove(e, id, arrName);
      else if (this.$store.state.selectedFilter == 1)
        chipsService.onBrandFilterRemove(e, id, arrName);
    },
    getName(id, name) {
      return chipsService.getRepName(id, name);
    },
  },
  mounted() {
    var filter = this.filter;

    var keys = Object.keys(filter);

    keys.map((x) => {
      if (Array.isArray(filter[x]) && x != "totalPersonnel") {
        filter[x].map((y) => {
          this.list.push({
            id: y,
            name: this.getName(y, x.toString()),
            arrName: x.toString(),
          });
        });
      }
    });

  },
  components: {
    Chip,
  },
};
</script>

<style scoped>
.selected-items {
  display: inline-block;
  /* display: flex;
    align-items: flex-start;*/
  width: 90%;
  border: 1px solid lightgray;
}
.selected-items .chip {
  margin-top: 5px;
  padding: 90px;
}
</style>
