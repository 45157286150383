import Vue from 'vue'
import {store} from './store';


import { library } from '@fortawesome/fontawesome-svg-core'
import { faUserSecret, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import { faYoutube, faLinkedin, faInstagram, faFacebook, faTwitter } from '@fortawesome/free-brands-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(faUserSecret, faEnvelope, faYoutube, faLinkedin, faInstagram, faFacebook, faTwitter)

Vue.component('font-awesome-icon', FontAwesomeIcon)

import EventBus from './common/event-bus';
window.EventBus = new EventBus;

// Prime
import 'primevue/resources/themes/nova/theme.css';
import 'primevue/resources/primevue.min.css';
import 'primeicons/primeicons.css';

import App from './App.vue'
import CompanyProfile from '@/components/CompanyProfile'
import ManufacturerProfile from '@/components/ManufacturerProfile'
import BrandProfile from '@/components/BrandProfile'
import SearchForm from '@/components/SearchForm'
import SingleProfile from '@/components/SingleProfile'
import ProfilesList from '@/components/ProfilesList'
import 'materialize-css/dist/js/materialize.min.js'
import router from './router'
import moment from 'moment'

Vue.config.productionTip = false

// Globally register your component
Vue.component('CompanyProfile', CompanyProfile);
Vue.component('ManufacturerProfile', ManufacturerProfile);
Vue.component('BrandProfile', BrandProfile);
Vue.component('SearchForm', SearchForm);
Vue.component('SingleProfile', SingleProfile);
Vue.component('ProfilesList', ProfilesList);

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY')
  }
})

Vue.filter('formatPhone', function (value) {
  if (value) {
    let cleaned = ('' + value).replace(/\D/g, '');      
    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);  
    if (match) {
      return '(' + match[1] + ')' + match[2] + '-' + match[3]
    }  
    return value
  }
})

Vue.filter('formatSemicolon', function (str) {
  var res = str.replace(/;(\S{1})/g, '; $1');
  return res;  
})



new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
