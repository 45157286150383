<template>
  <div class="brand-filtration">
    <FormLayout :showContent="true">
      <LabeledInput
        label="Brand Name"
        id="brand-company-search"
        placeholder="Brand Name"
        v-model="companyName"
        @onEnter="onEnter()"
      />
    </FormLayout>
    <br />
    <!--
    <br />
    <FormLayout :showContent="true">
      <LabeledInput
        label="ZIP code"
        id="zip-value-search2"
        placeholder="ZIP value"
        v-model="zipValue"
      />
    </FormLayout>

    <br />
    <FormLayout :bottomLine="true" :caption="regionCaption">
      <MultipleCheckbox
        :values="$store.state.regions"
        v-model="regionIds"
        :alpIndex="false"
        :height="150"
        :key="$store.getters.changeBrand"
      />
    </FormLayout>
    <FormLayout :bottomLine="true" :caption="stateCaption">
      <MultipleCheckbox
        :values="$store.state.states"
        v-model="stateIds"
        :alpIndex="false"
        :key="$store.getters.changeBrand"
        :height="150"
      />
    </FormLayout>
    <FormLayout :bottomLine="true" :caption="countryCaption">
      <MultipleCheckbox
        :values="$store.state.countries"
        v-model="countryIds"
        :alpIndex="false"
        :key="$store.getters.changeBrand"
      />
    </FormLayout>
    -->
    <FormLayout :bottomLine="true" :caption="brandCaption">
      <MultipleCheckbox
        :values="$store.state.brands"
        v-model="brandIds"
        :alpIndex="true"
        :searchable="true"
        :height="200"
        :key="$store.getters.changeBrand"
        :matchAll="$store.state.brandFilter.brandIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'brandIds')"
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="brandProductCategoriesCaption">
      <MultipleCheckbox
        :values="$store.state.brandProductCategories"
        v-model="brandProductCategoriesIds"
        :alpIndex="false"
        :searchable="false"
        :height="130"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="$store.state.brandFilter.brandProductCategoriesIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'brandProductCategoriesIds')"
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="bpcEquipmentCaption">
      <MultipleCheckbox
        :values="$store.state.bpcEquipment"
        v-model="bpcEquipmentIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.bpcEquipmentIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'bpcEquipmentIds')
        "
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="bpcSuppliesSmallwaresCaption">
      <MultipleCheckbox
        :values="$store.state.bpcSuppliesSmallwares"
        v-model="bpcSuppliesSmallwaresIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.bpcSuppliesSmallwaresIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'bpcSuppliesSmallwaresIds')
        "
      />
    </FormLayout>


    <FormLayout :bottomLine="true" :caption="bpcTabletopCaption">
      <MultipleCheckbox
        :values="$store.state.bpcTabletop"
        v-model="bpcTabletopIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.bpcTabletopIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'bpcTabletopIds')
        "
      />
    </FormLayout>


    <FormLayout :bottomLine="true" :caption="bpcFurnitureCaption">
      <MultipleCheckbox
        :values="$store.state.bpcFurniture"
        v-model="bpcFurnitureIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.bpcFurnitureIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'bpcFurnitureIds')
        "
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="bpcJanSanDispCaption">
      <MultipleCheckbox
        :values="$store.state.bpcJanSanDisp"
        v-model="bpcJanSanDispIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.bpcJanSanDispIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'bpcJanSanDispIds')
        "
      />
    </FormLayout>

    <FormLayout :bottomLine="true" :caption="bpcTechnologyCaption">
      <MultipleCheckbox
        :values="$store.state.bpcTechnology"
        v-model="bpcTechnologyIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.bpcTechnologyIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'bpcTechnologyIds')
        "
      />
    </FormLayout>



    <FormLayout :show-content="lookingForNewBrandsPartnersAllIds.length > 0" :bottomLine="true" :caption="lookingForNewBrandsPartnersAllCaption">
      <MultipleCheckbox
        :values="$store.state.lookingForNewBrandsPartnersAll"
        v-model="lookingForNewBrandsPartnersAllIds"
        :alpIndex="false"
        :searchable="false"
        :height="25"
        :key="NaN"
        :matchAllVisible=false
        @matchAllChanged="matchChanged($event, 'lookingForNewBrandsPartnersAllIds')"
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewEquipmentPartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewEquipmentPartners"
        v-model="lookingForNewEquipmentPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.lookingForNewEquipmentPartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewEquipmentPartnersIds')
        "
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewSuppliesSmallwaresPartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewSuppliesSmallwaresPartners"
        v-model="lookingForNewSuppliesSmallwaresPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter
            .lookingForNewSuppliesSmallwaresPartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewSuppliesSmallwaresPartnersIds')
        "
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewTabletopPartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewTabletopPartners"
        v-model="lookingForNewTabletopPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="170"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.lookingForNewTabletopPartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewTabletopPartnersIds')
        "
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewFurniturePartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewFurniturePartners"
        v-model="lookingForNewFurniturePartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="150"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.lookingForNewFurniturePartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewFurniturePartnersIds')
        "
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewJanSanDispPartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewJanSanDispPartners"
        v-model="lookingForNewJanSanDispPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.lookingForNewJanSanDispPartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewJanSanDispPartnersIds')
        "
      />
    </FormLayout>
    <FormLayout
      :bottomLine="true"
      :caption="lookingForNewTechnologyPartnersCaption"
    >
      <MultipleCheckbox
        :values="$store.state.lookingForNewTechnologyPartners"
        v-model="lookingForNewTechnologyPartnersIds"
        :alpIndex="false"
        :searchable="false"
        :height="70"
        :key="$store.getters.changeBrand"
        :matchAllVisible=true
        :matchAll="
          $store.state.brandFilter.lookingForNewTechnologyPartnersIdsMatchAll
        "
        @matchAllChanged="
          matchChanged($event, 'lookingForNewTechnologyPartnersIds')
        "
      />
    </FormLayout>

    <!--
    <FormLayout :bottomLine="true" :caption="marketSegmentsCaption">
      <MultipleCheckbox
        :values="$store.state.marketSegments"
        v-model="marketSegmentsIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="$store.getters.changeBrand"
      />
    </FormLayout>
    -->

    <FormLayout :show-content="showRegionContent" :bottomLine="true" :caption="regionsNeededCoverageCaption">
      <MultipleCheckbox
        :values="$store.state.regionsNeededCoverage"
        v-model="regionsNeededCoverageIds"
        :alpIndex="false"
        :searchable="false"
        :height="190"
        :key="NaN"
        :matchAllVisible=true
        :matchAll="$store.state.brandFilter.regionsNeededCoverageIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'regionsNeededCoverageIds')"
      />
    </FormLayout>
    <FormLayout :bottomLine="true" :caption="mfFirmAttrCaption">
      <MultipleCheckbox
        :values="$store.state.mfFirmAttributes"
        v-model="mfFirmAttributesIds"
        :alpIndex="false"
        :searchable="false"
        :height="90"
        :key="$store.getters.changeBrand"
        :matchAll="$store.state.brandFilter.mfFirmAttributesIdsMatchAll"
        @matchAllChanged="matchChanged($event, 'mfFirmAttributesIds')"
      />
    </FormLayout>
  </div>
</template>

<script>
import LabeledInput from "../common/LabeledInput";
import MultipleCheckbox from "../common/MultipleCheckbox";
import FormLayout from "../common/FormLayout";

export default {
  data() {
    return {};
  },
  computed: {
    showRegionContent() {
      return this.$store.state.brandFilter.regionsNeededCoverageIds.length > 0;
    },
    regionCaption() {
      return `Regions (${this.$store.state.regions.length})`;
    },
    stateCaption() {
      return `State(s)/Province(s) in Territory (${this.$store.state.states.length})`;
    },
    countryCaption() {
      return `Countries (${this.$store.state.countries.length})`;
    },
    brandCaption() {
      return `Brands (${this.$store.state.brands.length})`;
    },
    brandProductCategoriesCaption() {
      return `Product Categories - Overall (${this.$store.state.brandProductCategories.length})`;
    },
    lookingForNewBrandsPartnersAllCaption() {
      return `Looking for New Partners - All`;
    },
    equipmentCategoriesCaption() {
      return `Equipment Categories (${this.$store.state.equipmentCategories.length})`;
    },
    lookingForNewPartnersCaption() {
      return `Looking For New Partners (${this.$store.state.lookingForNewPartners.length})`;
    },
    suppliesSmallwaresCaption() {
      return `Supplies/Smallwares Categories (${this.$store.state.suppliesSmallwares.length})`;
    },
    tabletopCategoriesCaption() {
      return `Tabletop Categories (${this.$store.state.tabletopCategories.length})`;
    },
    bpcEquipmentCaption() {
      return `Product Categories - Equipment (${this.$store.state.bpcEquipment.length})`;
    },
    bpcSuppliesSmallwaresCaption() {
      return `Product Categories - Supplies (${this.$store.state.bpcSuppliesSmallwares.length})`;
    },
    bpcTabletopCaption() {
      return `Product Categories - Tabletop (${this.$store.state.bpcTabletop.length})`;
    },
    bpcFurnitureCaption() {
      return `Product Categories - Furniture (${this.$store.state.bpcFurniture.length})`;
    },
    bpcJanSanDispCaption() {
      return `Product Categories - Jan/San/Disp (${this.$store.state.bpcJanSanDisp.length})`;
    },
    bpcTechnologyCaption() {
      return `Product Categories - Technology (${this.$store.state.bpcTechnology.length})`;
    },
    lookingForNewEquipmentPartnersCaption() {
      return `Looking for New Partners - Equipment (${this.$store.state.lookingForNewEquipmentPartners.length})`;
    },
    lookingForNewSuppliesSmallwaresPartnersCaption() {
      return `Looking for New Partners - Supplies (${this.$store.state.lookingForNewSuppliesSmallwaresPartners.length})`;
    },
    lookingForNewTabletopPartnersCaption() {
      return `Looking for New Partners - Tabletop (${this.$store.state.lookingForNewTabletopPartners.length})`;
    },
    lookingForNewFurniturePartnersCaption() {
      return `Looking for New Partners - Furniture (${this.$store.state.lookingForNewFurniturePartners.length})`;
    },
    lookingForNewJanSanDispPartnersCaption() {
      return `Looking for New Partners - Jan/San/Disp (${this.$store.state.lookingForNewJanSanDispPartners.length})`;
    },
    lookingForNewTechnologyPartnersCaption() {
      return `Looking for New Partners - Technology (${this.$store.state.lookingForNewTechnologyPartners.length})`;
    },
    marketSegmentsCaption() {
      return `Market Segments (${this.$store.state.marketSegments.length})`;
    },
    regionsNeededCoverageCaption() {
      return `Regions Needed Coverage (${this.$store.state.regionsNeededCoverage.length})`;
    },
    totalPersonnelCaption() {
      return "Total Personnel";
    },
    mfFirmAttrCaption() {
      return `Manufacturer Attributes (${this.$store.state.mfFirmAttributes.length})`;
    },
    companyName: {
      get() {
        return this.$store.state.brandFilter.companyName;
      },
      set(val) {
        this.$store.state.brandFilter.companyName = val;
      },
    },
    zipValue: {
      get() {
        return this.$store.state.brandFilter.zipValue;
      },
      set(val) {
        this.$store.state.brandFilter.zipValue = val;
      },
    },
    stateIds: {
      get() {
        return this.$store.state.brandFilter.stateIds;
      },
      set(val) {
        this.$store.state.brandFilter.stateIds = val;
      },
    },
    regionIds: {
      get() {
        return this.$store.state.brandFilter.regionIds;
      },
      set(val) {
        this.$store.state.brandFilter.regionIds = val;
      },
    },
    countryIds: {
      get() {
        return this.$store.state.brandFilter.countryIds;
      },
      set(val) {
        this.$store.state.brandFilter.countryIds = val;
      },
    },
    brandIds: {
      get() {
        return this.$store.state.brandFilter.brandIds;
      },
      set(val) {
        this.$store.state.brandFilter.brandIds = val;
      },
    },
    brandProductCategoriesIds: {
      get() {
        return this.$store.state.brandFilter.brandProductCategoriesIds;
      },
      set(val) {
        this.$store.state.brandFilter.brandProductCategoriesIds = val;
      },
    },
    lookingForNewBrandsPartnersAllIds: {
      get() {
        return this.$store.state.brandFilter.lookingForNewBrandsPartnersAllIds;
      },
      set(val) {
        this.$store.state.brandFilter.lookingForNewBrandsPartnersAllIds = val;
      },
    },
    regionsNeededCoverageIds: {
      get() {
        return this.$store.state.brandFilter.regionsNeededCoverageIds;
      },
      set(val) {
        this.$store.state.brandFilter.regionsNeededCoverageIds = val;
      },
    },
    mfFirmAttributesIds: {
      get() {
        return this.$store.state.brandFilter.mfFirmAttributesIds;
      },
      set(val) {
        this.$store.state.brandFilter.mfFirmAttributesIds = val;
      },
    },
    lookingForNewEquipmentPartnersIds: {
      get() {
        return this.$store.state.brandFilter.lookingForNewEquipmentPartnersIds;
      },
      set(val) {
        this.$store.state.brandFilter.lookingForNewEquipmentPartnersIds = val;
      },
    },
    lookingForNewSuppliesSmallwaresPartnersIds: {
      get() {
        return this.$store.state.brandFilter
          .lookingForNewSuppliesSmallwaresPartnersIds;
      },
      set(val) {
        this.$store.state.brandFilter.lookingForNewSuppliesSmallwaresPartnersIds = val;
      },
    },
    lookingForNewTabletopPartnersIds: {
      get() {
        return this.$store.state.brandFilter.lookingForNewTabletopPartnersIds;
      },
      set(val) {
        this.$store.state.brandFilter.lookingForNewTabletopPartnersIds = val;
      },
    },
    lookingForNewFurniturePartnersIds: {
      get() {
        return this.$store.state.brandFilter.lookingForNewFurniturePartnersIds;
      },
      set(val) {
        this.$store.state.brandFilter.lookingForNewFurniturePartnersIds = val;
      },
    },
    lookingForNewJanSanDispPartnersIds: {
      get() {
        return this.$store.state.brandFilter.lookingForNewJanSanDispPartnersIds;
      },
      set(val) {
        this.$store.state.brandFilter.lookingForNewJanSanDispPartnersIds = val;
      },
    },
    lookingForNewTechnologyPartnersIds: {
      get() {
        return this.$store.state.brandFilter.lookingForNewTechnologyPartnersIds;
      },
      set(val) {
        this.$store.state.brandFilter.lookingForNewTechnologyPartnersIds = val;
      },
    },
    bpcEquipmentIds: {
      get() {
        return this.$store.state.brandFilter
          .bpcEquipmentIds;
      },
      set(val) {
        this.$store.state.brandFilter.bpcEquipmentIds = val;
      },
    },
    bpcSuppliesSmallwaresIds: {
      get() {
        return this.$store.state.brandFilter
          .bpcSuppliesSmallwaresIds;
      },
      set(val) {
        this.$store.state.brandFilter.bpcSuppliesSmallwaresIds = val;
      },
    },
    bpcTabletopIds: {
      get() {
        return this.$store.state.brandFilter.bpcTabletopIds;
      },
      set(val) {
        this.$store.state.brandFilter.bpcTabletopIds = val;
      },
    },
    bpcFurnitureIds: {
      get() {
        return this.$store.state.brandFilter.bpcFurnitureIds;
      },
      set(val) {
        this.$store.state.brandFilter.bpcFurnitureIds = val;
      },
    },
    bpcJanSanDispIds: {
      get() {
        return this.$store.state.brandFilter.bpcJanSanDispIds;
      },
      set(val) {
        this.$store.state.brandFilter.bpcJanSanDispIds = val;
      },
    },
    bpcTechnologyIds: {
      get() {
        return this.$store.state.brandFilter.bpcTechnologyIds;
      },
      set(val) {
        this.$store.state.brandFilter.bpcTechnologyIds = val;
      },
    },
    marketSegmentsIds: {
      get() {
        return this.$store.state.brandFilter.marketSegmentsIds;
      },
      set(val) {
        this.$store.state.brandFilter.marketSegmentsIds = val;
      },
    },
  },
  methods: {
    matchChanged(e, name) {
      this.$store.state.brandFilter[`${name}MatchAll`] = e;
    },
    onEnter() {
      this.$emit("onEnter");
    },
  },
  components: {
    LabeledInput,
    MultipleCheckbox,
    FormLayout,
  },
};
</script>

<style scoped>
.brand-filtration .slider {
  margin-bottom: 15px;
}
.brand-filtration .slider .range {
  margin-bottom: 8px;
}
</style>
