import {store} from '../store';
import {httpService} from './httpService';

class SearchPageService {
    getValue(value, name) {
        if (!value) return [];
        return [{
            name: name,
            value: value,
        }];
    }

    getArr(arr, name) {
        if (!arr || arr.length === 0) return [];
        let result = arr.map(x => {
            return {
                name: name,
                value: x
            }
        });
        return result;
    }

    combineArr(arr, arrForAdd) {
        return [...arr, ...arrForAdd];
    }

    getRepFilterObj() {
        let get = store.getters;
        let filter = get.repFilter;
        let searchArr = [];

        searchArr = this.combineArr(searchArr, this.getValue(filter.companyName, "name"));
        searchArr = this.combineArr(searchArr, this.getValue(filter.zipValue, "zipValue"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.countryIds, "country"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.regionIds, "region"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.stateIds, "state"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.brandIds, "brand"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.categoryIds, "category"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.equipmentIds, "equipment"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.partnerIds, "partner"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.repFirmIds, "repFirms"));
        searchArr = this.combineArr(searchArr, this.getValue(get.repFilterTotalPersonnel, "totalPersonnel"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.productCategoriesIds, "productCategories"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.equipmentCategoriesIds, "equipmentCategories"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.suppliesSmallwaresCategoriesIds, "suppliesSmallwares"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.tabletopCategoriesIds, "tabletopCategories"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.furnitureCategoriesIds, "furnitureCategories"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.janSanDispCategoriesIds, "janSanDispCategories"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.technologyCategoriesIds, "technologyCategories"));

        searchArr = this.combineArr(searchArr, this.getArr(filter.repFirmAttributesIds, "repFirmAttributes"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.marketSegmentsIds, "marketSegments"));

        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewPartnersIds, "lookingForNewPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewEquipmentPartnersIds, "lookingForNewEquipmentPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewSuppliesSmallwaresPartnersIds, "lookingForNewSuppliesSmallwaresPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewTabletopPartnersIds, "lookingForNewTabletopPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewFurniturePartnersIds, "lookingForNewFurniturePartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewJanSanDispPartnersIds, "lookingForNewJanSanDispPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewTechnologyPartnersIds, "lookingForNewTechnologyPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewRepsPartnersAllIds, "lookingForNewRepsPartnersAll"));

        // matchAll
        Object.keys(filter).map((x) => {
            let index = x.indexOf('MatchAll');
            if (!Array.isArray(filter[x]) && index > -1) {
                let name = x.replace('IdsMatchAll', '');
                searchArr = this.combineArr(searchArr, this.getValue(filter[x], name));
            }
        });

        return {search: searchArr};
    }

    getBrandFilterObj() {
        let get = store.getters;
        let filter = get.brandFilter;
        let searchArr = [];

        searchArr = this.combineArr(searchArr, this.getValue(filter.companyName, "name"));
        searchArr = this.combineArr(searchArr, this.getValue(filter.zipValue, "zipValue"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.countryIds, "country"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.regionIds, "region"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.stateIds, "state"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.brandIds, "brand"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.brandProductCategoriesIds, "brandProductCategories"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.mfFirmAttributesIds, "mfFirmAttributes"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.marketSegmentsIds, "marketSegments"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.regionsNeededCoverageIds, "regionsNeededCoverage"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewEquipmentPartnersIds, "lookingForNewEquipmentPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewSuppliesSmallwaresPartnersIds, "lookingForNewSuppliesSmallwaresPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewTabletopPartnersIds, "lookingForNewTabletopPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewFurniturePartnersIds, "lookingForNewFurniturePartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewJanSanDispPartnersIds, "lookingForNewJanSanDispPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewTechnologyPartnersIds, "lookingForNewTechnologyPartners"));
        searchArr = this.combineArr(searchArr, this.getArr(filter.lookingForNewBrandsPartnersAllIds, "lookingForNewBrandsPartnersAll"));

        // matchAll
        Object.keys(filter).map((x) => {
            let index = x.indexOf('MatchAll');
            if (!Array.isArray(filter[x]) && index > -1) {
                let name = x.replace('IdsMatchAll', '');
                searchArr = this.combineArr(searchArr, this.getValue(filter[x], name));
            }
        });
        return {search: searchArr};
    }

    search(filter, page) {
        let searchObj = filter == 0 ? this.getRepFilterObj() : this.getBrandFilterObj();

        searchObj.page = page;
        searchObj.currentFilter = filter;
        console.log("searchObj", searchObj);

        return httpService.post("/test/SearchProfiles", searchObj);
    }

    searchByRepId(e) {
        return httpService.post("/test/SearchProfiles", {
            search: [
                {
                    name: "id",
                    value: e,
                },
            ]
        }).then(x => {
            if (x.data.items.length > 0) {
                store.commit("resetBrandForm")
                store.commit("changeToRepsTabFilter");
                store.state.repFilter.companyName = x.data.items[0].name;
            }
            return x;
        });
    }

    searchByEquipmentName(value) {

        let searchObj = this.getBrandFilterObj();
        searchObj.search = [{name: "name", value: value}];
        searchObj.currentFilter = 1;
        console.log("new Search obj", searchObj);
        return httpService.post("/test/SearchProfiles", searchObj).then(x => {
            console.log('x.data', x.data);
            if (x.data.items.length > 0) {
                store.commit("resetBrandForm")
                store.state.brandFilter.companyName = value;
            }
            return x;
        });
    }

    getBrands() {
        httpService.post("/test/GetBrandsList").then(
            (response) => {
                store.state.brands = [...response.data];
            },
            (error) => console.log(error)
        );
    }

    getCsvReport(filter, page){
        let searchObj = filter == 0 ? this.getRepFilterObj() : this.getBrandFilterObj();

        searchObj.currentFilter = filter;

        return httpService.post("/test/exportToCsv", searchObj);

    }

    getLocationData() {
        console.log("Get location data");
        httpService.get("/FiltersSource/GetRepsFiltersSource").then(
            (response) => {
                // console.log("location response", response);
                store.state.regions = [...response.data.regions];
                store.state.states = [...response.data.statesProvinces];
                store.state.countries = [...response.data.countries];

                store.state.brandProductCategories = [...response.data.brandProductCategories];

                store.state.productCategories = [...response.data.productCategories];
                store.state.equipmentCategories = [...response.data.equipmentCategories];
                store.state.suppliesSmallwaresCategories = [...response.data.supplySmallwaresCategories];
                store.state.tabletopCategories = [...response.data.tabletopCategories];
                store.state.furnitureCategories = [...response.data.furnitureCategories];
                store.state.janSanDispCategories = [...response.data.janSanDispCategories];
                store.state.technologyCategories = [...response.data.technologyCategories];

                store.state.repFirmAttributes = [...response.data.repFirmAttributes];
                store.state.mfFirmAttributes = [...response.data.mfFirmAttributes];

                store.state.marketSegments = [...response.data.marketSegments];
                store.state.regionsNeededCoverage = [...response.data.regionsNeededCoverage];

                store.state.lookingForNewPartners = [...response.data.lookingForNewPartners];
                store.state.lookingForNewEquipmentPartners = [...response.data.lookingForNewEquipmentPartners];
                store.state.lookingForNewSuppliesSmallwaresPartners = [...response.data.lookingForNewSuppliesSmallwaresPartners];
                store.state.lookingForNewTabletopPartners = [...response.data.lookingForNewTabletopPartners];
                store.state.lookingForNewFurniturePartners = [...response.data.lookingForNewFurniturePartners];
                store.state.lookingForNewJanSanDispPartners = [...response.data.lookingForNewJanSanDispPartners];
                store.state.lookingForNewTechnologyPartners = [...response.data.lookingForNewTechnologyPartners];
                store.state.lookingForNewBrandsPartnersAll = [...response.data.lookingForNewBrandsPartnersAll];
                store.state.lookingForNewRepsPartnersAll = [...response.data.lookingForNewRepsPartnersAll];
                store.state.bpcEquipment = [...response.data.brandProductCategoriesEquipment];
                store.state.bpcSuppliesSmallwares = [...response.data.brandProductCategoriesSuppliesSmallwares];
                store.state.bpcTabletop = [...response.data.brandProductCategoriesTabletop];
                store.state.bpcFurniture = [...response.data.brandProductCategoriesFurniture];
                store.state.bpcJanSanDisp = [...response.data.brandProductCategoriesJanSanDisp];
                store.state.bpcTechnology = [...response.data.brandProductCategoriesTechnology];
            },
            (error) => console.log(error)
        );
    }
}

export let searchPageService = new SearchPageService();