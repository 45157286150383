import Vue from 'vue';
import Vuex from 'vuex';
Vue.use(Vuex);

export const store = new Vuex.Store({
  state: {
    regions: [],
    states: [],
    countries: [],
    brands: [],
    productCategories: [],
    equipmentCategories: [],
    suppliesSmallwaresCategories: [],
    tabletopCategories: [],
    furnitureCategories: [],
    janSanDispCategories: [],
    technologyCategories: [],
    repFirmAttributes: [],
    mfFirmAttributes: [],
    brandProductCategories: [],
    marketSegments: [],
    regionsNeededCoverage: [],
    lookingForNewPartners: [],
    lookingForNewEquipmentPartners: [],
    lookingForNewSuppliesSmallwaresPartners: [],
    lookingForNewTabletopPartners: [],
    lookingForNewFurniturePartners: [],
    lookingForNewJanSanDispPartners: [],
    lookingForNewTechnologyPartners: [],
    lookingForNewRepsPartnersAll: [],
    lookingForNewBrandsPartnersAll: [],
    bpcEquipment: [],
    bpcSuppliesSmallwares: [],
    bpcTabletop: [],
    bpcFurniture: [],
    bpcJanSanDisp: [],
    bpcTechnology: [],

    // auth
    authInfo: {
      isAuth: false,
      userName: ''
    },

    filter: {
      companyName: '',
      brandIds: [],
      number: [11, 33]
    },
    selectedFilter: 0,
    repFilter: {
      companyName: '',
      zipValue: '',
      regionIds: [],
      stateIds: [],
      countryIds: [],
      brandIds: [],
      totalPersonnel: [1, 100],
      repFirmIds: [],
      productCategoriesIds: [],
      equipmentCategoriesIds: [],
      suppliesSmallwaresCategoriesIds: [],
      tabletopCategoriesIds: [],
      furnitureCategoriesIds: [],
      janSanDispCategoriesIds: [],
      technologyCategoriesIds: [],

      repFirmAttributesIds: [],
      marketSegmentsIds: [],

      lookingForNewPartnersIds: [],
      lookingForNewEquipmentPartnersIds: [],
      lookingForNewSuppliesSmallwaresPartnersIds: [],
      lookingForNewTabletopPartnersIds: [],
      lookingForNewFurniturePartnersIds: [],
      lookingForNewJanSanDispPartnersIds: [],
      lookingForNewTechnologyPartnersIds: [],
      lookingForNewRepsPartnersAllIds: [],
      //
      regionIdsMatchAll: false,
      stateIdsMatchAll: false,
      countryIdsMatchAll: false,
      brandIdsMatchAll: false,
      repFirmIdsMatchAll: false,

      productCategoriesIdsMatchAll: false,
      equipmentCategoriesIdsMatchAll: false,
      suppliesSmallwaresCategoriesIdsMatchAll: false,
      tabletopCategoriesIdsMatchAll: false,
      furnitureCategoriesIdsMatchAll: false,
      janSanDispIdsCategoriesMatchAll: false,
      technologyCategoriesIdsMatchAll: false,

      repFirmAttributesIdsMatchAll: false,
      marketSegmentsIdsMatchAll: false,

      lookingForNewPartnersIdsMatchAll: false,
      lookingForNewEquipmentPartnersIdsMatchAll: false,
      lookingForNewSuppliesSmallwaresPartnersIdsMatchAll: false,
      lookingForNewTabletopPartnersIdsMatchAll: false,
      lookingForNewFurniturePartnersIdsMatchAll: false,
      lookingForNewJanSanDispPartnersIdsMatchAll: false,
      lookingForNewTechnologyPartnersIdsMatchAll: false,
    },
    brandFilter: {
      companyName: '',
      zipValue: '',
      regionIds: [],
      stateIds: [],
      countryIds: [],
      brandIds: [],
      brandProductCategoriesIds: [],
      mfFirmAttributesIds: [],
      regionsNeededCoverageIds: [],
      marketSegmentsIds: [],
      lookingForNewEquipmentPartnersIds: [],
      lookingForNewSuppliesSmallwaresPartnersIds: [],
      lookingForNewTabletopPartnersIds: [],
      lookingForNewFurniturePartnersIds: [],
      lookingForNewJanSanDispPartnersIds: [],
      lookingForNewTechnologyPartnersIds: [],
      lookingForNewBrandsPartnersAllIds: [],
      bpcEquipmentIds: [],
      bpcSuppliesSmallwaresIds: [],
      bpcTabletopIds: [],
      bpcFurnitureIds: [],
      bpcJanSanDispIds: [],
      bpcTechnologyIds: [],
      // 
      stateIdsMatchAll: false,
      countryIdsMatchAll: false,
      brandIdsMatchAll: false,
      brandProductCategoriesIdsMatchAll: false,
      mfFirmAttributesIdsMatchAll: false,
      regionsNeededCoverageIdsMatchAll: false,
      marketSegmentsIdsMatchAll: false,
      lookingForNewEquipmentPartnersIdsMatchAll: false,
      lookingForNewSuppliesSmallwaresPartnersIdsMatchAll: false,
      lookingForNewTabletopPartnersIdsMatchAll: false,
      lookingForNewFurniturePartnersIdsMatchAll: false,
      lookingForNewJanSanDispPartnersIdsMatchAll: false,
      lookingForNewTechnologyPartnersIdsMatchAll: false,
      bpcEquipmentIdsMatchAll: false,
      bpcSuppliesSmallwaresIdsMatchAll: false,
      bpcTabletopIdsMatchAll: false,
      bpcFurnitureIdsMatchAll: false,
      bpcJanSanDispIdsMatchAll: false,
      bpcTechnologyIdsMatchAll: false
    }

  },
  getters: {
    changeTest: state => {
      let count = 0;
      Object.keys(state.repFilter).map((x) => {
        if (Array.isArray(state.repFilter[x]) && x != 'totalPersonnel') {
          state.repFilter[x].map((y) => {
            count++;
          });
        }
      });
      return count;
    },
    repFilter: state => state.repFilter,
    repFilterTotalPersonnel: state => {
      return `${state.repFilter.totalPersonnel[0]},${state.repFilter.totalPersonnel[1]}`;
    },
    changeBrand: state => {
      let count = 0;
      Object.keys(state.brandFilter).map((x) => {
        if (Array.isArray(state.brandFilter[x])) {
          state.brandFilter[x].map((y) => {
            count++;
          });
        }
      });
      return count;
    },
    brandFilter: state => state.brandFilter
  },
  mutations: {
    resetRepForm: state => {
      Object.keys(state.repFilter).map((x) => {
        // set arrays to default
        console.log('tt', typeof x)
        if (Array.isArray(state.repFilter[x]) && x != 'totalPersonnel') {
          state.repFilter[x] = [];
        }
        else if (x == 'totalPersonnel') {
          state.repFilter[x] = [1, 100];
        }
        else if (x == 'companyName') {
          state.repFilter[x] = '';
        }
        else if (x == 'zipValue') {
          state.repFilter[x] = '';
        }
        else if (x.indexOf('MatchAll') > -1) {
          state.repFilter[x] = false;
        }
      });
    },
    resetBrandForm: state => {
      Object.keys(state.brandFilter).map((x) => {
        // set arrays to default
        if (Array.isArray(state.brandFilter[x])) {
          state.brandFilter[x] = [];
        }
        else if (x == 'companyName') {
          state.brandFilter[x] = '';
        }
        else if (x == 'zipValue') {
          state.brandFilter[x] = '';
        }
        else if (x.indexOf('MatchAll') > -1) {
          state.brandFilter[x] = false;
        }
      });
    },
    changeToRepsTabFilter: state => {
      state.selectedFilter = 0;
    },
    changeToBrandsTabFilter: state => {
      state.selectedFilter = 1;
    },

  }
});
