<template>
  <div class="loader">
    <div>
      <ProgressBar v-if="showLoading" mode="indeterminate" style="height: 5px; color: red;"/>
    </div>
  </div>
</template>
<script>
import ProgressBar from "primevue/progressbar";
export default {
  data() {
    return {
      showLoading: false,
    };
  },
  created() {
    window.EventBus.addEventListener("loading-started", (event) => {
      event;
      this.showLoading = true;
    });

    window.EventBus.addEventListener("loading-ended", (event) => {
      event;
      this.showLoading = false;
    });
  },
  components: {
    ProgressBar,
  },
};
</script>

<style>
.loader {
  position: fixed;
  top: 0;
  width: 100%;
}
/* .loader > div {
    position: absolute;
top: 40px; left: 40px;
} */
</style>