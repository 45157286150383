<template>
  <footer>
    <div class="contact-area">
      <div class="container">
        <div></div>
        <div class="col" style="text-align: left">
          <h4>Contact</h4>
          <p class="address">
            1199 Euclid Avenue
            <br />
            Atlanta, Georgia 30307
            <br />
            United States
          </p>
          <p class="phone">404-214-9474</p>
          <div class="social-icons">
            <a
              href="https://www.facebook.com/MAFSIAssoc"
              title="Find us on Facebook"
              class="icon facebook"
              target="_blank"
            >
              <font-awesome-icon :icon="['fab', 'facebook']" />
            </a>
            <a
              href="https://twitter.com/MAFSIAssoc"
              title="Tweet at us"
              class="icon twitter"
              target="_blank"
              ><font-awesome-icon :icon="['fab', 'twitter']" /></a>
            <a
              href="https://www.linkedin.com/company/mafsi/"
              title="Connect with us on LinkedIn"
              class="icon linked-in"
              target="_blank"
              ><font-awesome-icon :icon="['fab', 'linkedin']" /></a>
            <a
              href="https://www.instagram.com/mafsi_foodservice/"
              title="Connect with us on Instagram"
              class="icon instagram"
              target="_blank"
              ><font-awesome-icon :icon="['fab', 'instagram']" /></a>
            <a
              href="https://www.youtube.com/channel/UCEmIOiWOKEYzek3-oM8BX0g"
              title="Find us on YouTube"
              class="icon youtube"
              target="_blank"
              > <font-awesome-icon :icon="['fab', 'youtube']" /></a>
            <a
              href="https://member.mafsi.org/contact-us"
              title="Contact Us"
              class="icon facebook"
              >
              <!-- <span class="novicon novicon-envelope"></span> -->
              <font-awesome-icon :icon="['fas', 'envelope']" />
              </a>
          </div>
        </div>
        <div class="col">
          <div class="editor-container">
            <br /><br /><br /><br />
            <!-- <p> -->
            MAFSI IS... Everywhere food is.<br />
            And everywhere you are.
            <!-- </p> -->
            <p>
              240+ rep agencies, 200+ manufacturers, 21 associates and 2,400+
              members strong. Spanning North America, feeding 300 million
              people, and changing an industry.
            </p>
          </div>
        </div>
        <div class="col" style="margin-left: 70px">
          <div class="editor-container">
            <div>
              <p>
                <img
                  src="https://assets.noviams.com/novi-file-uploads/mafsi/logos/mafsi/MAFSI_NEW_LOGO_WHITE_2020_with_tag_for_Novi.png"
                  class="img-responsive"
                  caption="false"
                  alt="MAFSI Logo"
                  width="500"
                  height="174"
                  data-mce-src="https://assets.noviams.com/novi-file-uploads/mafsi/logos/mafsi/MAFSI_NEW_LOGO_WHITE_2020_with_tag_for_Novi.png"
                />
              </p>
              <p style="margin-bottom: 0">
                <a
                  class="link"
                  href="https://www.mafsi.org/"
                  target="_blank"
                  rel="noopener noreferrer"
                  >www.mafsi.org
                </a>
              </p>
              <a
                class="link"
                href="https://www.mafsi.org/2022-mafsi-conference"
                target="_blank"
                rel="noopener noreferrer"
                >www.mafsi22.org
              </a>
              <br />
              <a
                class="link"
                href="http://specpath.org/account/welcome?ReturnUrl=%2fdashboard"
                target="_blank"
                rel="noopener noreferrer"
                >www.specpath.org
              </a>
              <br />
              <a
                class="link"
                href="https://mafsi.learnupon.com/store"
                target="_blank"
                rel="noopener noreferrer"
                >www.foodserviceacademy.org
              </a>
            </div>
          </div>
        </div>
        <div></div>
      </div>
    </div>
    <div style="border-top: 1px solid #1db954" class="color-strip"></div>
    <div class="affiliates-area">
      <div class="container-btm">
        <!-- <div class="affiliates" style="display: none;"></div> -->
        <div></div>
        <div class="aff">
          <a class="novi" href="http://www.noviams.com" target="_blank"
            ><img
              alt="Novi AMS"
              src="https://member.mafsi.org/img/noviams-logo.png"
              class="img-responsive"
            /><br />Association Management Software</a
          >
            <p>
            Copyright © 2021 - Manufacturers' Agents Association for the
            Foodservice Industry.
            <a href="https://member.mafsi.org/legal">Legal</a>
          </p>
        </div>
        
        <div></div>
      </div>
    </div>
  </footer>
  <!-- <div class="footer">
      <p> <img src="@/assets/footer-stub.jpg" class="footer-image"/> </p>
   </div> -->
</template>

<script>

export default {
  name: "Footer",
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

footer{
  margin: 0px;
}
/* .container {
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;
} */
footer .affiliates-area p a {
  padding-left: 5px;
  text-decoration: none;
  color: #fff;
}
footer .affiliates-area p a:hover {
  color: #1db954;
}

footer .affiliates-area {
  position: relative;
  min-height: 75px;
  border-top: 2px solid #1db954;
  color: #fff;
  background-color: #222326;
}
footer .affiliates-area p {
  font: normal 0.75em Circular, sans-serif;
  line-height: 18px;
  font-weight: 400;
  color: #fff;
  text-transform: none;
  letter-spacing: 0;
}

footer .affiliates-area .novi {
  float: none;
  display: inline-block;
  width: 50%;
  margin-top: 13px;
  font-size: 0.45em;
  font-family: Arial, Helvetica, sans-serif;
  line-height: 1em;
  letter-spacing: 0.3em;
  text-align: left;
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
}
footer .affiliates-area .affiliates {
  position: relative;
  z-index: 10;
  float: none;
  display: table;
  width: 100%;
  min-height: 75px;
  padding: 10px;
  text-align: center;
  background: #fff;
}
.link {
  color: #fff;
  text-decoration: none;
}
.link:hover {
  color: green;
  text-decoration: underline;
}

footer .contact-area h4 {
  font: 700 1.5em Circular, sans-serif;
  line-height: 36px;
  font-weight: 700;
  color: #fff;
  text-transform: none;
  letter-spacing: 0;
}

footer .contact-area {
  position: relative;
  z-index: 6;
  padding: 5px 0 30px;
  color: #fff;
  text-align: center;
  background: #222326;
  background-image: none;
  background-size: cover;
}

footer .container {
  padding-right: 15px;
  padding-left: 15px;
  margin-right: auto;
  margin-left: auto;
  display: grid;
  grid-template-columns: 2fr 2fr 2fr 2fr 2fr;
}

footer .container-btm {
  display: grid;
  grid-template-columns: 2fr 6fr 2fr;
}

.aff{
  display: flex;
  align-items: center;
  justify-content: center;
}

.aff img {
  height: 25px;
}
.aff p {
text-align: left;
  margin-top: 20px;

}
footer .contact-area .address,
footer .contact-area .phone {
  display: block;
  margin-bottom: 10px;
}

footer .contact-area li,
footer .contact-area p,
footer .contact-area ul {
  font: normal 1.125rem Circular, sans-serif;
  line-height: 27px;
  font-weight: 400;
  color: #fff;
  text-transform: none;
  letter-spacing: 0;
}
.phone:before {
  content: "Call: ";
  display: inline-block;
  white-space: pre-wrap;
}

footer .contact-area .phone:after {
  content: "Fax: 888-254-0033 \A Email: info@mafsi.org";
  display: block;
  white-space: pre-wrap;
}

footer .contact-area .social-icons {
  margin: 20px 0;
}

footer .contact-area .social-icons .icon.facebook,
footer .contact-area .social-icons .icon.instagram,
footer .contact-area .social-icons .icon.linked-in,
footer .contact-area .social-icons .icon.twitter,
footer .contact-area .social-icons .icon.youtube {
  background: #000 !important;
  color: #fff !important;
  border: 1px solid #000 !important;
}

footer .contact-area .social-icons .icon.facebook:hover,
footer .contact-area .social-icons .icon.instagram:hover,
footer .contact-area .social-icons .icon.linked-in:hover,
footer .contact-area .social-icons .icon.twitter:hover,
footer .contact-area .social-icons .icon.youtube:hover {
  background: #000 !important;
  color: #1db954 !important;
  border: 1px solid #000 !important;
}

footer .contact-area .social-icons .icon {
  display: inline-block;
  margin: 5px 7px 0 0;
  padding: 6px 10px;
  border-radius: 50%;
  border: 2px solid #fff;
  color: #fff;
  font-size: 13px;
}

/* footer .contact-area a:hover {
    font-weight: 400;
} */

.novicon {
  position: relative;
  top: 1px;
  display: inline-block;
  font-family: Novi;
  font-style: normal;
  font-weight: 400;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.novicon-facebook:before {
  /* content: "\e60d"; */
  content: "ff";
}

.novicon-twitter:before {
  content: "tt";
}

.novicon-linked-in:before {
  content: "ll";
}

.novicon-instagram:before {
  content: "ii";
}

.novicon-youtube:before {
  content: "yy";
}

/* .novicon-envelope:before {
  content: '\f0e0';
} */

.editor-container {
  position: relative;
  text-align: left;
}

.img-responsive {
  display: block;
  max-width: 100%;
  height: auto;
}

img {
  vertical-align: middle;
}

footer .contact-area li,
footer .contact-area p,
footer .contact-area ul {
  font: normal 1.125rem Circular, sans-serif;
  line-height: 27px;
  font-weight: 400;
  color: #fff;
  text-transform: none;
  letter-spacing: 0;
}

</style>
