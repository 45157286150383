<template>
  <div class="search-form">
    <span class="element-caption">Company Name:</span>
    <input
      class="full-width"
      v-model="companyName"
      placeholder="Type any word in company name"
    />
    <br />
    <br />
    <br />

    <MultipleCheckbox
      :values="brands"
      v-model="stateBrandIds"
      :alpIndex="true"
      :searchable="true"
      :key="brands.length"
      :height="200"
    />

    <br />
    <span>{{ slider }}</span>
    <Slider v-model="slider" :range="true" />
    <br />

    <div>
      <span class="element-caption">State:</span>

      <select class="full-width" name="State" v-model="selectedState" size="1">
        <option selected="selected" value="Any">Any</option>
        <option value="AL">Alabama</option>
        <option value="AK">Alaska</option>
        <option value="AZ">Arizona</option>
        <option value="AR">Arkansas</option>
        <option value="CA">California</option>
        <option value="CO">Colorado</option>
        <option value="CT">Connecticut</option>
        <option value="DE">Delaware</option>
        <option value="DC">District of Columbia</option>
        <option value="FL">Florida</option>
        <option value="GA">Georgia</option>
        <option value="HI">Hawaii</option>
        <option value="ID">Idaho</option>
        <option value="IL">Illinois</option>
        <option value="IN">Indiana</option>
        <option value="IA">Iowa</option>
        <option value="KS">Kansas</option>
        <option value="KY">Kentucky</option>
        <option value="LA">Louisiana</option>
        <option value="ME">Maine</option>
        <option value="MD">Maryland</option>
        <option value="MA">Massachusetts</option>
        <option value="MI">Michigan</option>
        <option value="MN">Minnesota</option>
        <option value="MS">Mississippi</option>
        <option value="MO">Missouri</option>
        <option value="MT">Montana</option>
        <option value="NE">Nebraska</option>
        <option value="NV">Nevada</option>
        <option value="NH">New Hampshire</option>
        <option value="NJ">New Jersey</option>
        <option value="NM">New Mexico</option>
        <option value="NY">New York</option>
        <option value="NC">North Carolina</option>
        <option value="ND">North Dakota</option>
        <option value="OH">Ohio</option>
        <option value="OK">Oklahoma</option>
        <option value="OR">Oregon</option>
        <option value="PA">Pennsylvania</option>
        <option value="RI">Rhode Island</option>
        <option value="SC">South Carolina</option>
        <option value="SD">South Dakota</option>
        <option value="TN">Tennessee</option>
        <option value="TX">Texas</option>
        <option value="UT">Utah</option>
        <option value="VT">Vermont</option>
        <option value="VA">Virginia</option>
        <option value="WA">Washington</option>
        <option value="WV">West Virginia</option>
        <option value="WI">Wisconsin</option>
        <option value="WY">Wyoming</option>
        <option value="AB">Alberta</option>
        <option value="BC">British Columbia</option>
        <option value="MB">Manitoba</option>
        <option value="NB">New Brunswick</option>
        <option value="NF">Newfoundland</option>
        <option value="NT">Northwest Territories</option>
        <option value="NS">Nova Scotia</option>
        <option value="ON">Ontario</option>
        <option value="PE">Prince Edward Island</option>
        <option value="QC">Quebec</option>
        <option value="SK">Saskatchewan</option>
        <option value="YT">Yukon</option>
      </select>
      <br />
    </div>

    <!-- <span>Selected State: {{ selectedState }}</span> -->

    <span class="element-caption">Product Categories:</span>
    <select class="full-width" name="Category" size="6" multiple>
      <option selected="selected">Any</option>
      <option>Heavy Equipment</option>
      <option>Light Equipment</option>
      <option>Supplies</option>
      <option>Tabletop</option>
      <option>Furniture</option>
      <option>Technology</option>
      <option>Janitorial and Sanitation</option>
    </select>
    <br />
    <!-- <span>Categories: {{ selectedCategory }}</span> -->

    <span class="element-caption">Product Sub-Categories:</span>
    <select
      class="full-width"
      name="Subcategory"
      v-model="selectedSubcategory"
      size="6"
    >
      <option selected="selected">Any</option>
      <option>Bakery Equipment/Bakeware</option>
      <option>Beverage/Bar Equipment</option>
      <option>Cooking &amp; Kitchen Tools</option>
      <option>Custom Fabrication</option>
      <option>Dispensing Equipment</option>
      <option>Display/Merchandising Equipment</option>
      <option>Disposables</option>
      <option>Food Preparation Equipment</option>
      <option>Furnishings &amp; Decor</option>
      <option>Holding &amp; Transport Equipment</option>
      <option>Ice Cream Equipment</option>
      <option>Ice Machines</option>
      <option>Janitorial &amp; Sanitation Equipment</option>
      <option>Janitorial &amp; Sanitation Supplies</option>
      <option>Primary Cooking Equipment</option>
      <option>Refrigeration</option>
      <option>Sanitation Equipment</option>
      <option>Serving</option>
      <option>Serving/Holding/Transport Equipment</option>
      <option>Signs &amp; Displays</option>
      <option>Smallwares</option>
      <option>Smallwares/Cooking &amp; Kitchen Tools</option>
      <option>Storage &amp; Handling Equipment</option>
      <option>Tabletop &amp; Servingware</option>
      <option>Technology</option>
      <option>Ventilation</option>
      <option>Warewashing</option>
      <option>Waste Handling &amp; Reduction</option>
      <option>Water Filtration Equipment</option>
    </select>
    <br />

    <p>
      <button
        v-on:click="fnAlert"
        class="btn waves-effect waves-light"
        name="btSearch"
      >
        SEARCH
      </button>

      <button v-on:click="fnAlert">RESET</button>
    </p>
  </div>
</template>

<script>
import { httpService } from "../services/httpService";
import MultipleCheckbox from "./common/MultipleCheckbox.vue";
import Slider from "primevue/slider";

export default {
  name: "SearchForm",
  data() {
    return {
      brands: [],
    };
  },
  computed: {
    companyName: {
      get() {
        return this.$store.state.filter.companyName;
      },
      set(value) {
        this.$store.state.filter.companyName = value;
      },
    },
    slider: {
      get() {
        return this.$store.state.filter.number;
      },
      set(val) {
        this.$store.state.filter.number = val;
      },
    },
    stateBrandIds: {
      get() {
        return this.$store.state.filter.brandIds;
      },
      set(val) {
        this.$store.state.filter.brandIds = val;
      },
    },
    selectedBrandIds() {
      var checkedBrands = this.brands.filter((x) => x.checked);
      var ids = checkedBrands.map((x) => x.id);
      return ids;
    },
  },
  watch: {
    brands: function (newValue, oldValue) {
      this.$store.state.filter.brandIds = [...this.selectedBrandIds];
      console.log("brands watch");
    },
  },
  props: {
    selectedState: String,
    selectedCategory: Array,
    selectedSubcategory: String,
  },
  methods: {
    fnAlert: function (event) {
      console.log("STATE brand ids", this.$store.state.filter.brandIds);
      this.$emit("searchEvent");
    },
    getBrands() {
      console.log("Get brands from server");
      httpService.post("/test/GetBrandsList").then(
        (response) => {
          let brands = response.data.map((x) => {
            var brandItem = {};
            brandItem.id = x.id;
            brandItem.name = x.name;
            brandItem.checked = false;
            return brandItem;
          });

          brands.sort((a, b) =>
            a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1
          );
          this.brands = [...brands];
          console.log("Brands", this.brands);
        },
        (error) => console.log(error)
      );
    },
  },
  mounted() {
    this.getBrands();
  },
  components: {
    MultipleCheckbox,
    Slider,
  },
};
</script>

<style scoped>
.element-caption {
  display: block;
  text-align: left;
  padding-left: 1em;
  font-weight: bold;
  font-size: 0.8em;
  margin-top: 1em;
}

.full-width {
  width: 90%;
}

.search-form {
  padding: 0.5em;
}
</style>
